// @material-ui/icons
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import MapIcon from "@material-ui/icons/Map";
import LogIcon from "@material-ui/icons/Description";
import { gaupalikaWard, selectedLanguage } from "../../variable/global";
import Home from "../dashboard/home/Home";
import UserProfile from "../dashboard/user";
import Log from "../dashboard/log";
import Report from "../dashboard/report";
import SearchIcon from "@material-ui/icons/Search";
import HouseSurveyIcon from "@material-ui/icons/Receipt";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import ErrorIcon from "@material-ui/icons/Error";
import InstitutionSurveyIcon from "@material-ui/icons/AccountBalance";
import HouseSurvey from "../dashboard/houseSurvey/";
import InstitutionSurvey from "../dashboard/institutionSurvey/";
const dashboardRoutes = [
  {
    path: "/home",
    exact: true,
    hasChild: false,
    sidebarName: "गृह पृष्ठ",
    navbarName: gaupalikaWard[selectedLanguage],
    icon: HomeIcon,
    component: Home,
  },

  {
    sidebarName: "विवरण",
    icon: HomeIcon,
    hasChild: true,
    child: [
      {
        path: "/analysis-by-house",
        sidebarName: "घरधुरी विवरण",
        navbarName: "घरधुरी विवरण",
        icon: HomeIcon,
        component: Home,
      },
      {
        path: "/analysis-by-family",
        sidebarName: "पारिवारिक विवरण",
        navbarName: "पारिवारिक विवरण",
        icon: PersonIcon,
        component: Home,
      },
      // {
      // 	path: "/analysis-by-institution",
      // 	sidebarName: "संस्थागत विवरण",
      // 	navbarName: "संस्थागत विवरण",
      // 	icon: PersonIcon,
      // 	component: Home
      // }
    ],
  },
  {
    sidebarName: "खोज्नुहोस",
    icon: SearchIcon,
    hasChild: true,
    child: [
      {
        path: "/advance-search-house",
        sidebarName: "घर विस्तृत खोजी",
        navbarName: "घर विस्तृत खोजी",
        icon: PersonIcon,
        component: UserProfile,
      },
      // {
      // 	path: "/house-number-search",
      // 	sidebarName: "घर नम्बर बाट खोजी",
      // 	navbarName: "घर नम्बर बाट खोजी",
      // 	icon: PersonIcon,
      // 	component: Home
      // },
      {
        path: "/search-by-death",
        sidebarName: "मृतकको खोजी",
        navbarName: "मृतकको खोजी",
        icon: PersonIcon,
        component: Home,
      },
      // ,
      // {
      // 	path: "/advance-search-institution",
      // 	sidebarName: "संस्थागत विस्तृत खोजी",
      // 	navbarName: "संस्थागत विस्तृत खोजी",
      // 	icon: PersonIcon,
      // 	component: Home
      // }
    ],
  },

  {
    path: "/survey-by-house",
    hasChild: false,
    sidebarName: "घर सर्वेक्षण",
    navbarName: "घर सर्वेक्षण",
    icon: HouseSurveyIcon,
    component: HouseSurvey,
  },
  {
    path: "/survey-by-institution",
    sidebarName: "संस्था सर्वेक्षण",
    navbarName: "संस्था सर्वेक्षण",
    icon: InstitutionSurveyIcon,
    hasChild: false,
    // child: [
    // 	{
    // 		path: "/institution/school",
    // 		sidebarName: "स्कूल विवरण",
    // 		navbarName: "स्कूल विवरण"
    // 		// icon: HomeIcon
    // 	},
    // 	{
    // 		path: "/institution/ngo",
    // 		sidebarName: "सरकारी / गैर सरकारी",
    // 		navbarName: "सरकारी / गैर सरकारी"
    // 		// icon: PersonIcon
    // 	},
    // 	{
    // 		path: "/institution/police",
    // 		sidebarName: "प्रहरी चौकी",
    // 		navbarName: "प्रहरी चौकी"
    // 		// icon: PersonIcon
    // 	},
    // 	{
    // 		path: "/institution/bank",
    // 		sidebarName: "बैंक र वित्तीय संस्था",
    // 		navbarName: "बैंक र वित्तीय संस्था"
    // 		// icon: PersonIcon
    // 	},
    // 	{
    // 		path: "/institution/hotel",
    // 		sidebarName: "होटल र लज",
    // 		navbarName: "होटल र लज"
    // 		// icon: PersonIcon
    // 	},
    // 	{
    // 		path: "/institution/mine",
    // 		sidebarName: "खानी",
    // 		navbarName: "खानी"
    // 		// icon: PersonIcon
    // 	},
    // 	{
    // 		path: "/institution/temple",
    // 		sidebarName: "मठ मन्दिर",
    // 		navbarName: "मठ मन्दिर"
    // 		// icon: PersonIcon
    // 	},
    // 	{
    // 		path: "/institution/forest",
    // 		sidebarName: "वनक्षेत्र",
    // 		navbarName: "वनक्षेत्र"
    // 		// icon: PersonIcon
    // 	},
    // 	{
    // 		path: "/institution/lake",
    // 		sidebarName: "ताल",
    // 		navbarName: "ताल"
    // 		// icon: PersonIcon
    // 	},
    // 	{
    // 		path: "/institution/ground",
    // 		sidebarName: "मैदान",
    // 		navbarName: "मैदान"
    // 		// icon: PersonIcon
    // 	},
    // 	{
    // 		path: "/institution/market",
    // 		sidebarName: "मार्केट",
    // 		navbarName: "मार्केट"
    // 		// icon: PersonIcon
    // 	},
    // 	{
    // 		path: "/institution/communication",
    // 		sidebarName: "संचार टावर",
    // 		navbarName: "संचार टावर"
    // 		// icon: PersonIcon
    // 	},
    // 	{
    // 		path: "/institution/herb",
    // 		sidebarName: "जडिबुटी",
    // 		navbarName: "जडिबुटी"
    // 		// icon: PersonIcon
    // 	},
    // 	{
    // 		path: "/institution/bridge",
    // 		sidebarName: "पुल",
    // 		navbarName: "पुल"
    // 		// icon: PersonIcon
    // 	},
    // 	{
    // 		path: "/institution/fm",
    // 		sidebarName: "एफ.एम",
    // 		navbarName: "एफ.एम"
    // 		// icon: PersonIcon
    // 	},
    // 	{
    // 		path: "/institution/footballClub",
    // 		sidebarName: "फुटबल क्लब",
    // 		navbarName: "फुटबल क्लब"
    // 		// icon: PersonIcon
    // 	},
    // 	{
    // 		path: "/institution/youthClub",
    // 		sidebarName: "युथ क्लब / युवा क्लब",
    // 		navbarName: "युथ क्लब / युवा क्लब"
    // 		// icon: PersonIcon
    // 	},
    // 	{
    // 		path: "/institution/farmerClub",
    // 		sidebarName: "कृषक समूह",
    // 		navbarName: "कृषक समूह"
    // 		// icon: PersonIcon
    // 	},
    // 	{
    // 		path: "/institution/mothersClub",
    // 		sidebarName: "आमा समूह",
    // 		navbarName: "आमा समूह"
    // 		// icon: PersonIcon
    // 	}
    // ]
  },
  {
    sidebarName: "SMS",
    icon: AnnouncementIcon,
    hasChild: true,
    child: [
      {
        path: "/sms/home",
        exact: true,
        sidebarName: "Home Page",
        navbarName: "Smart SMS",
        icon: HomeIcon,
      },
      {
        path: "/sms/message",
        sidebarName: "Message",
        navbarName: "Message",
        icon: PersonIcon,
        exact: true,
      },
      {
        path: "/sms/desgination",
        sidebarName: "Designation",
        navbarName: "Designation",
        icon: PersonIcon,
        exact: true,
      },
      {
        path: "/sms/people",
        sidebarName: "People",
        navbarName: "People",
        icon: PersonIcon,
        exact: true,
      },
      {
        path: "/sms/group",
        sidebarName: "Group",
        navbarName: "Group",
        icon: PersonIcon,
        exact: true,
      },
    ],
  },
  {
    path: "/users",
    hasChild: false,
    sidebarName: "प्रयोगकर्ता",
    navbarName: "प्रयोगकर्ता",
    icon: HomeIcon,
    component: UserProfile,
  },
  {
    path: "/log",
    hasChild: false,
    sidebarName: "लग",
    navbarName: "लग",
    icon: HomeIcon,
    component: Log,
  },
  // {
  // 	path: "/error",
  // 	hasChild: false,
  // 	sidebarName: "कोबोमा त्रुटि",
  // 	navbarName: "कोबोमा त्रुटि",
  // 	icon: ErrorIcon,
  // 	component: Log
  // },
  {
    path: "/report",
    sidebarName: "रिपोर्ट",
    navbarName: "रिपोर्ट",
    icon: PersonIcon,
    component: Report,
  },
  {
    path: "/map",
    sidebarName: "नक्शा",
    navbarName: "नक्शा",
    icon: MapIcon,
    component: Map,
  },

  // { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" }
];

export default dashboardRoutes;
