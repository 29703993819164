import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import Table from "@material-ui/core/Table";
import { TableHead, TableRow, TableCell, Grid } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import ClipLoader from "react-spinners/ClipLoader";
import GetHouseDetail from "../../../dashboard/search/advancedHouseSearch/houseNumber/GetHouseDetail";
import {
	clearHouseDetail,
	getHouseByHouseId
} from "../../../../actions/searchAction";
import FullScreenDialog from "./FullScreen";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { connect } from "react-redux";
class HouseScroll extends React.Component {
	state = {
		houses: [],
		house: {},
		houseNumber: null,
		houseOpen: false
	};

	onHouseNumberClick = hn => {
		this.setState({ houseOpen: true, houseNumber: hn }, () => {
			this.props.getHouseByHouseId(hn);
		});
	};
	handleCloseHouse = name => {
		this.setState({ [name]: false, house: {} });
	};
	componentDidMount() {}
	componentDidUpdate(prevprops) {
		if (prevprops.house !== this.props.house) {
			this.setState({
				house: this.props.house
			});
		}
		if (this.props.args !== prevprops.args) {
			const { args, functions } = this.props;
			console.log("args", args, functions);
			axios
				.post("/api/analysis/generic/house", { function: functions, args })
				.then(res => {
					console.log("response from api", res.data);
					this.setState({ houses: res.data });
				});
		}
	}
	render() {
		const { open, handleClose } = this.props;

		return (
			<div>
				<Dialog
					open={open}
					onClose={handleClose}
					scroll="paper"
					aria-labelledby="scroll-dialog-title"
				>
					<DialogTitle id="scroll-dialog-title">House Detail</DialogTitle>
					<DialogContent dividers={true}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>S.N</TableCell>
									<TableCell>House Number</TableCell>
									<TableCell>Tole Name</TableCell>
									<TableCell>Street Name</TableCell>
									<TableCell>Answerer Name</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{this.state.houses &&
									this.state.houses.map((each, i) => (
										<TableRow>
											<TableCell>{i + 1}</TableCell>
											<TableCell>{each.houseNumber}</TableCell>
											<TableCell>{each.toleName}</TableCell>
											<TableCell>{each.streetName}</TableCell>
											<TableCell>{each.answererName}</TableCell>
											<TableCell>
												<VisibilityIcon
													onClick={() => this.onHouseNumberClick(each._id)}
													// className={classnames(classes.preview, classes.icon)}
												/>
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
						{/*  */}
						<table>
							<tr></tr>
						</table>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							OK
						</Button>
					</DialogActions>
				</Dialog>
				{this.state.houseOpen && (
					<FullScreenDialog
						name="houseOpen"
						open={this.state.houseOpen}
						title={`House Detail `}
						handleClose={this.handleCloseHouse}
					>
						<Grid container spacing={24}>
							{this.props.load ? (
								<Grid
									style={{ marginTop: "40vh" }}
									container
									direction="row"
									justify="center"
									alignItems="center"
								>
									<Grid item>
										<ClipLoader
											sizeUnit={"px"}
											size={84}
											color={"#00ACC1"}
											loading={this.props.load}
										/>
									</Grid>
								</Grid>
							) : (
								<Grid
									container
									direction="row"
									justify="center"
									alignItems="flex-start"
								>
									{this.state.house.success ? " aayo" : "xaina "}
									{this.state.house && (
										<Grid item xs={9}>
											<GetHouseDetail house={this.state.house} />
										</Grid>
									)}
								</Grid>
							)}
						</Grid>
					</FullScreenDialog>
				)}
			</div>
		);
	}
}

const mapStateToProps = ({ search: { house, load } }) => ({
	house,
	load
});
export default connect(
	mapStateToProps,
	{ clearHouseDetail, getHouseByHouseId }
)(HouseScroll);
