const name = { nepali: "नाम", english: "Name" };
const category = { nepali: "कुन किसिम", english: "Category" };
const establish = { nepali: "निर्माण भएको बर्ष", english: "Establish" };
const description = { nepali: "विवरण", english: "description" };
const member = { nepali: "कर्मचारी संख्या", english: "Member" };
const address = { nepali: "ठेगाना", english: "Address" };
const phone = { nepali: "फोन नम्बर", english: "phone" };
const designation = { nepali: "पद", english: "designation" };
const count = { nepali: "संख्या", english: "count" };
const latitude = { nepali: "अक्षांश", english: "latitude" };
const longitude = { nepali: "देशान्तर", english: "longitude" };
const wardQuestion = { nepali: "वडा नम्बर", english: "ward number" };
const ward = [
	{
		nepali: "१",
		english: "1",
		value: "1"
	},
	{
		nepali: "१",
		english: "1",
		value: "1"
	},
	{
		nepali: "१",
		english: "1",
		value: "1"
	},
	{
		nepali: "१",
		english: "1",
		value: "1"
	},
	{
		nepali: "१",
		english: "1",
		value: "1"
	},
	{
		nepali: "१",
		english: "1",
		value: "1"
	},
	{
		nepali: "१",
		english: "1",
		value: "1"
	},
	{
		nepali: "१",
		english: "1",
		value: "1"
	},
	{
		nepali: "१",
		english: "1",
		value: "1"
	},
	{
		nepali: "१",
		english: "1",
		value: "1"
	},
	{
		nepali: "१",
		english: "1",
		value: "1"
	}
];
const photo = { nepali: "फोटो", english: "photo" };
const specName = { nepali: "विशेषता", english: "photo" };
const specValue = { nepali: "विशेषता", english: "photo" };
const add = { nepali: "थप्नुहोस", english: "Add" };
const remove = { nepali: "हटाउनुहोस", english: "Remove" };
const darta = { nepali: "दर्ता भयो", english: "darta" };
const itemTitle = { nepali: "", english: "" };
const area = { nepali: "क्षेत्रफल", english: "" };
const kachiCount = { nepali: "कच्ची संख्या", english: "" };
const pakkiCount = { nepali: "पक्की संख्या", english: "" };
const kachiRoomCount = { nepali: "कच्ची कोठा संख्या", english: "" };
const pakkiRoomCount = { nepali: "पक्की कोठा संख्या", english: "" };
const gharTitle = { nepali: "भवन सम्बन्धि", english: "No of House" };
const roomTitle = { nepali: "कोठा संख्या", english: "No of room" };
const benchCapacity = { nepali: "बेन्चको क्षमता", english: "Bench Capacity" };
const ground = { nepali: "खेल मैदान", english: "Play ground" };
const compound = { nepali: "क्षेत्र", english: "Area" };
const dead = { nepali: "मृत्यु", english: "Dead" };
const injured = { nepali: "घाइते", english: "Injured" };
const childplayground = {
	nepali: "बाल मैत्री मैदान",
	english: "Children playground"
};
const cberga = {
	nepali: "CBERGA कार्यनयाँन भएको ?",
	english: ""
};
const schoolFacilityTitle = {
	nepali: "विद्यालय सुविधा ",
	english: "School Facility"
};
const healthInfo = [
	{
		nepali: "बिशेषज्ञ चिकित्सक",
		english: "",
		value: "experienceDoctor"
	},
	{
		nepali: "सामान्य चिकित्सक",
		english: "",
		value: "normalDoctor"
	},
	{
		nepali: "हे.अ.",
		english: "",
		value: "heya"
	},
	{
		nepali: "नर्स",
		english: "",
		value: "nurse"
	},
	{
		nepali: "अहेव",
		english: "",
		value: "ahewo"
	},
	{
		nepali: "अनमी",
		english: "",
		value: "aanami"
	},
	{
		nepali: "ल्याव टेक्निसियन",
		english: "",
		value: "labTechnician"
	}
];

const fiscalYear = { nepali: "मिति", english: "" };

const staffTitleList = [
	{
		nepali: "स्विकृत दरबन्दी",
		english: "",
		value: "accepted"
	},
	{
		nepali: "दरबन्दी अनुसार कार्यरत",
		english: "",
		value: "portionWork"
	},
	{
		nepali: "अन्य शिक्षक संख्या",
		english: "",
		value: "otherTeacher"
	},
	{
		nepali: "जम्मा शिक्षक शिक्षिका",
		english: "",
		value: "totalTeacher"
	},
	{
		nepali: "स्थायी शिक्षक, शिक्षिका",
		english: "",
		value: "permanentTeacher"
	},
	{
		nepali: "अस्थायी शिक्षक, शिक्षिका",
		english: "",
		value: "temporaryTeacher"
	},
	{
		nepali: "तालिम प्राप्त शिक्षक",
		english: "",
		value: "skillTraining"
	}
];

const teacherTitleList = [
	{
		nepali: "स्विकृत दरबन्दी",
		english: "",
		value: "accepted"
	},
	{
		nepali: "दरबन्दी अनुसार कार्यरत",
		english: "",
		value: "portionWork"
	},
	{
		nepali: "अन्य शिक्षक संख्या",
		english: "",
		value: "otherTeacher"
	},
	{
		nepali: "जम्मा शिक्षक शिक्षिका",
		english: "",
		value: "totalTeacher"
	},
	{
		nepali: "स्थायी शिक्षक, शिक्षिका",
		english: "",
		value: "permanentTeacher"
	},
	{
		nepali: "अस्थायी शिक्षक, शिक्षिका",
		english: "",
		value: "temporaryTeacher"
	},
	{
		nepali: "तालिम प्राप्त शिक्षक",
		english: "",
		value: "skillTraining"
	}
];

const healthFacilityTitle = {
	nepali: "स्वास्थ्य संस्था र उपलब्ध सेवाहरुको विवरण",
	english: ""
};

const healthLabel = { nepali: "स्वास्थ्य संस्थाका सेवाहरु", english: "" };
const shayaCountQn = { nepali: "शैया संख्या", english: "" };
const vaccineTitle = {
	nepali: "५ वर्ष मुनीका खोप लिएका वाल बालिकाको विवरण",
	english: ""
};

const vaccineQn = {
	nepali: "खोप लिने र ५ बर्ष मुनिका बालबालिका संख्या",
	english: ""
};
const vaccineList = [
	{
		nepali: "१ बर्ष मूनिका",
		english: "",
		value: "1_year_below"
	},
	{
		nepali: "२ बर्ष मूनीका",
		english: "",
		value: "2_year_below"
	},
	{
		nepali: "५ वर्ष मूनिका",
		english: "",
		value: "5_year_below"
	},
	{
		nepali: "विसिजी",
		english: "",
		value: "vicious"
	},
	{
		nepali: "पेण्टाभ्यालेन्ट",
		english: "",
		value: "pentavalent"
	},
	{
		nepali: "पोलियो तेश्रो",
		english: "",
		value: "polioThird"
	},
	{
		nepali: "दादुरा र रुबेला",
		english: "",
		value: "dadura"
	},
	{
		nepali: "जापानिज इन्से फलाइटिस",
		english: "",
		value: "japanese"
	},
	{
		nepali: "ब्ृद्धि अनुगमन गरिएका",
		english: "",
		value: "monitoring"
	},
	{
		nepali: "ब्ृद्धि अनुगमन गरिएका मध्ये कुषोषित",
		english: "",
		value: "malnourishedMonitor"
	}
];

const healthFacility = [
	{
		nepali: "प्रसुती सेवा",
		english: "",
		value: "delivery"
	},
	{
		nepali: "ल्याव",
		english: "",
		value: "lab"
	},
	{
		nepali: "पर्र्याप्त औषधिको व्यवस्था",
		english: "",
		value: "medicine"
	},
	{
		nepali: "क्लिनिक",
		english: "",
		value: "clinic"
	},
	{
		nepali: "एक्सरे सेवा",
		english: "",
		value: "xrays"
	},
	{
		nepali: "परिवार नियोजन",
		english: "",
		value: "familyPlanning"
	},
	{
		nepali: "खोप सेवा",
		english: "",
		value: "vaccine"
	},
	{
		nepali: "परामर्श सेवा",
		english: "",
		value: "consultation"
	},
	{
		nepali: "सुरक्षित मातृत्व सेवा",
		english: "",
		value: "safeMotherhood"
	},
	{
		nepali: "रक्त सञ्चार सेवा",
		english: "",
		value: "bloodBank"
	},
	{
		nepali: "एमबुलेन्स सेवा",
		english: "",
		value: "ambulance"
	},
	{
		nepali: "इमरजेन्सी सेवा",
		english: "",
		value: "emergency"
	}
];
const healthTitle = {
	nepali: "चिकित्सक र अन्य कर्मचारीको ढरबन्दी /संख्या",
	english: ""
};
const schoolFacility = [
	{
		nepali: "अपङ्गमैत्री शौचालय",
		english: "Disable Toilet",
		value: "disableToilet"
	},
	{
		nepali: "शुद्ध खानेपानी",
		english: "Has Drinking Water",
		value: "hasDrinkingWater"
	},
	{
		nepali: "कक्षा कोठामा डस्टबिनको सुविधा",
		english: "",
		value: "hasDustbinInClass"
	},
	{
		nepali: "इन्टरनेटको सुविधा",
		english: "",
		value: "hasInternet"
	},
	{
		nepali: "हात्धुने ठाउँको व्यवस्था",
		english: "",
		value: "hasHandWash"
	},
	{
		nepali: "प्रयोगसाला",
		english: "",
		value: "hasLab"
	},
	{
		nepali: "लौगिक मैत्री",
		english: "",
		value: "native"
	},
	{
		nepali: "एकीकृत पाठ्यक्रम",
		english: "Intergrated Course",
		value: "intergratedCourse"
	}
];
const schoolFacilityQn = { nepali: "विद्यालयमा भएको सुविधा", english: "" };
const classNineEducation = {
	nepali: "कक्षा ९ देखी प्राविधिक शिक्षा सन्चालन भएको",
	english: ""
};
const classNineEducationYes = {
	nepali: "छ भने कुन विषयको हो",
	english: ""
};

const vegetationAnimal = {
	nepali: "वनस्पति र जिवजन्तुहरु",
	english: ""
};
const grazingTypeQn = {
	nepali: "पाटन चरनको प्रकार",
	english: ""
};

const grazingType = [
	{
		nepali: "निजी",
		english: "",
		value: "private"
	},
	{
		nepali: "सार्वजनिक",
		english: "",
		value: "public"
	}
];
const furniture = { nepali: "आवश्यकता अनुसार्को फर्नीचर", english: "" };
const facultyQn = { nepali: "सन्काय", english: "Faculty" };
const collegelevel = { nepali: "पढाई हुने तह", english: "" };
const collegeSubject = {
	nepali: "पढाई हुने मुख्य विषयहरु",
	english: ""
};
const toilet = { nepali: "शौचालय", english: "Toilet" };
const hastoilet = { nepali: "शौचालय छ/छैन", english: "Toilet yes / no" };
const teacherMale = { nepali: "पुरुष", english: "Male" };
const teacherFemale = { nepali: "महिला", english: "Female" };
const maleCount = {
	nepali: "पुरुष शौचालय संख्या",
	english: "No of jeans toilet"
};
const femaleCount = {
	nepali: "महिला शौचालय संख्या",
	english: "No of female toilet"
};
const staffCount = {
	nepali: "कर्मचारी शौचालय संख्या",
	english: "No of staff toilet"
};
const staffTitle = { nepali: "कर्मचारी संख्या", english: "No of employee" };
const hasdrinking = {
	nepali: "खानेपानी छ/छैन",
	english: "Drinking water yes/no"
};
const appointAs = { nepali: "नियुक्ती", english: "Appoint" };
const staff = { nepali: "कर्मचारी", english: "Employee" };
const student = { nepali: "विद्यार्थी संख्या", english: "Student" };
const schoolClass = { nepali: "कक्षा", english: "Class" };
const malestudent = { nepali: "छात्र", english: "Male student" };
const femalestudent = { nepali: "छात्रा", english: "Female student" };
const website = { nepali: "वेबसाईट", english: "Website" };
const email = { nepali: "ईमेल", english: "Email" };
const schooltype = { nepali: "बिद्यालय प्रकार", english: "Type of school" };
const schoollevel = { nepali: "बिद्यालय तह", english: "School level" };
const specTitle = { nepali: "विशेषता भर्नुहोस", english: "" };
const position = { nepali: "पद", english: "Postiton" };
const emergencyNo = { nepali: "आकस्मिक नम्बर", english: "Emergency No" };
const instituteTitle = { nepali: "संस्थान प्रकार", english: "Instution type" };
const buildingCount = { nepali: "भवनको संख्या", english: "No of building" };
const consumedUser = {
	nepali: "लाभान्वित संख्या",
	english: "Profitable family"
};
const teacher = { nepali: "शिक्षक शिक्षीका", english: "" };
const vehicleTitle = {
	nepali: "सवारी साधन",
	english: "Means of transportation"
};
const memberTitle = { nepali: "सदस्य विवरण", english: "Member detail" };
const clubActive = { nepali: "सकृय छ / छैन", english: "Active yes / no" };
const categoryTitle = { nepali: "वर्ग", english: "category" };
const suffixCategoryList = { english: "'s details", nepali: " को विवरण" };
const fiscal = { nepali: "आर्थिक बर्ष", english: "Fiscal Year" };
const dartachalani = { nepali: "दर्ता चलनी नं", english: "Darta Chalani No" };
const budget = { nepali: "बजेट /लागत", english: "Budget" };
const timespan = { nepali: "समय अवधी", english: "Timespan" };
const studentProd = { nepali: "वडगत लाभान्वित जन्संख्या", english: "Timespan" };
const staffProd = { nepali: "शिक्षक/शिक्षिका संख्या", english: "Timespan" };
const targetedCategory = {
	nepali: "लक्षित वर्ग",
	english: "Targeted Category"
};
const helper = { nepali: "सहयोगी संस्था", english: "Helping Organization" };
const effectedHouse = {
	nepali: "लाभान्वित घरधुरी",
	english: "Effected Household"
};
const pdf = { nepali: "पिडिएफ", english: "PDF" };
const categoryList = [
	{
		nepali: "स्कूल",
		english: "School",
		value: "school"
	},
	{
		nepali: "कलेज",
		english: "College",
		value: "college"
	},
	{
		nepali: "प्राविधिक शिक्षा",
		english: "Technical Education",
		value: "technicalEducation"
	},
	{
		nepali: "प्रौड शिक्षा",
		english: "Prod Education",
		value: "prodEducation"
	},
	{
		nepali: "सरकारी संस्थान / गैर सरकारी संस्थान",
		english: "NGO/INGO",
		value: "ngo"
	},
	{
		nepali: "सुरक्षा निकाय विवरण",
		english: "Police Station",
		value: "police"
	},
	{
		nepali: "बैंक र वित्तीय संस्था",
		english: "Bank And Finance",
		value: "bank"
	},
	{
		nepali: "सामुदायिक भवन",
		english: "Community Hall",
		value: "communityHall"
	},
	{
		nepali: "सरकारी कार्यालय तथा कर्मचारीहरुको सरकारी बासस्थान",
		english: "Quarter",
		value: "quarter"
	},
	{
		nepali: "अस्पताल",
		english: "Hospital",
		value: "hospital"
	},
	{
		nepali: "स्वास्थ्य चौकी",
		english: "Health Post",
		value: "healthPost"
	},
	// {
	// 	nepali: "पशु (भेटेनरी)/उपचार केन्द्र",
	// 	english: "Animal Veterinary",
	// 	value: "animalVeterinary"
	// },
	{
		nepali: "पर्यटकिय  क्षेत्र",
		english: "Tourist Place",
		value: "touristPlace"
	},
	{
		nepali: "पार्क",
		english: "Park",
		value: "park"
	},
	{
		nepali: "होटल र लज",
		english: "Hotel And Lodge",
		value: "hotel"
	},
	{
		nepali: "खानी",
		english: "Mine",
		value: "mine"
	},
	{
		nepali: "वनक्षेत्र",
		english: "Forest",
		value: "forest"
	},
	{
		nepali: "राष्ट्रिय निकुञ्ज",
		english: "Nikunja",
		value: "nikunja"
	},
	{
		nepali: "पुल",
		english: "Bridge",
		value: "bridge"
	},
	{
		nepali: "मैदान",
		english: "Ground",
		value: "ground"
	},
	{
		nepali: "हाट बजार मार्केट",
		english: "Market",
		value: "market"
	},
	{
		nepali: "जडीबुटी तथा वनस्पति",
		english: "Flora",
		value: "flora"
	},
	{
		nepali: "केबलकार",
		english: "Cable Car",
		value: "cableCar"
	},
	{
		nepali: "तुइन भएको खोला/नदी",
		english: "",
		value: "tuin"
	},
	{
		nepali: "प्रमुख खोला/नदी",
		english: "",
		value: "mainRiver"
	},
	{
		nepali: "संचार टावर",
		english: "Communication Tower",
		value: "communication"
	},
	{
		nepali: "एफ.एम",
		english: "Fm",
		value: "fm"
	},
	{
		nepali: "पत्र पत्रिका",
		english: "Newspaper",
		value: "newspaper"
	},
	{
		nepali: "केवल नेटवर्क",
		english: "Cable Network",
		value: "cableNetwork"
	},
	{
		nepali: "मठ मन्दिर",
		english: "Temple",
		value: "temple"
	},
	{
		nepali: "मुख्य व्यवसाय",
		english: "Main Business",
		value: "mainBusiness"
	},
	{
		nepali: "प्रमुख मेला, चाडपर्व तथा जात्राहरु",
		english: "Festival",
		value: "festival"
	},
	{
		nepali: "मुख्य बस टर्मिनल",
		english: "Bus Park",
		value: "busPark"
	},
	{
		nepali: "सार्वजनिक शौचालय",
		english: "Public Toilet",
		value: "publicToilet"
	},

	{
		nepali: "विपद घटनाहरुमा क्षति (विगत ३ वर्ष)",
		english: "Incident Loss",
		value: "incidentLoss"
	},
	{
		nepali: "वाढी, पहिरोको जोखिम क्षेत्र",
		english: "Risk Management",
		value: "possibleRisk"
	},
	{
		nepali: "पशुपन्छी",
		english: "Animal",
		value: "animal"
	},
	{
		nepali: "चरण क्षेत्र",
		english: "Animal Place",
		value: "animalPlace"
	},
	{
		nepali: "कृषि तथा पशु फार्म",
		english: "Animal Farm",
		value: "animalFarm"
	},
	{
		nepali: "मुख्य मेकानिकल वर्कसप",
		english: "Service Center",
		value: "serviceCenter"
	},
	{
		nepali: "तालिम केन्द्र",
		english: "Trainning Institute",
		value: "trainningInstitute"
	},
	{
		nepali: "विधुत गृह",
		english: "Power House",
		value: "powerHouse"
	},
	{
		nepali: "विद्युतीय ट्रान्समिसन लाईन",
		english: "Transmission Line",
		value: "transmissionLine"
	},
	{
		nepali: "सडक सञ्जाल",
		english: "",
		value: "roadNetwork"
	},
	{
		nepali: "निशुल्क वाइफाइ उपलब्ध भएका स्थान",
		english: "",
		value: "freeWifi"
	},
	{
		nepali: "क्लबहरु",
		english: "Clubs",
		value: "club"
	},

	{
		nepali: "परिवारको विवरण",
		english: "",
		value: "villageCounilFamily"
	},
	{
		nepali: "कर्मचारीहरुको विवरण",
		english: "",
		value: "villageCounilStaff"
	}
];
const instituteType = [
	{
		nepali: "मदरसा",
		english: "Madarsa",
		value: "madarasa"
	},
	{
		nepali: "गुम्बा",
		english: "Gumba",
		value: "gumba"
	},
	{
		nepali: "संस्थागत",
		english: "private",
		value: "private"
	},
	{
		nepali: "सामुदायिक",
		english: "community",
		value: "community"
	}
];
const hotelType = [
	{
		nepali: "आबासीय",
		english: "Residental",
		value: "residental"
	},
	{
		nepali: "रिसोर्ट",
		english: "Resort",
		value: "resort"
	},
	{
		nepali: "होमस्टे",
		english: "Homestay",
		value: "homestay"
	},
	{
		nepali: "रेस्टुरेन्ट",
		english: "Resturant",
		value: "resturant"
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other"
	}
];
const schoolLevel = [
	{
		nepali: "बाल विकास कक्षा",
		english: "Kinder Garden",
		value: "kinder_garden"
	},
	{
		nepali: "पूर्व प्राथमिक",
		english: "Pre Primary",
		value: "pre_primary"
	},
	{
		nepali: "आधारभूत तह",
		english: "Primary",
		value: "primary"
	},
	{
		nepali: "माध्यमिक तह",
		english: "Secondary",
		value: "secondary"
	},
	{
		nepali: "प्राविधिक एस.एल.सी. (Overseer)",
		english: "SLC",
		value: "slc"
	},
	{
		nepali: "स्नातक तह",
		english: "Bachelor",
		value: "bachelor"
	}
];

const studentLevel = [
	{
		nepali: "पूर्व प्राथमिक (१ कक्षा भन्दा तल )",
		english: "Pre Primary",
		value: "pre_primary"
	},
	{
		nepali: "आधारभूत तह",
		english: "Primary",
		value: "primary"
	},
	{
		nepali: "माध्यमिक तह",
		english: "Secondary",
		value: "secondary"
	},
	{
		nepali: "अपाङ्गता भएको विद्यार्थी",
		english: "Disable Students",
		value: "disable"
	},
	{
		nepali: "छात्रवृत्ति पाउने विद्यार्थी",
		english: "scholarship",
		value: "scholarship"
	}
];

const fieldType = { nepali: "कुन बिभाग", english: "Which depart" };

const instituteList = ["School"];

const deleteMsg = {
	nepali: "के तपाई प्रयोगकर्ता हटाउन चहानुहुन्छ ?",
	english: "Are you sure you want to delete the user?"
};

const agree = { nepali: "हुन्छ ", english: "Agree" };

const disagree = { nepali: "हुदैन", english: "Disagree" };

const mainAnimalQn = {
	nepali: "प्रमुख जनावर तथा चरा चुरुंगी हरु",
	english: ""
};

const forestInfoQn = { nepali: "वनको सेवा", english: "" };

const forestInfoList = [
	{
		nepali: "वन उपभोक्ता समिति",
		english: "",
		value: "committee"
	},
	{
		nepali: "वन उपभोक्ता महासंघमा आवद्धता",
		english: "",
		value: "consistency"
	},
	{
		nepali: "वन कार्ययोजना",
		english: "",
		value: "planning"
	},
	{
		nepali: "नियमित बैठक बस्ने गरेको",
		english: "",
		value: "regularMeeting"
	},
	{
		nepali: "वन भित्र सामुहिक खेति",
		english: "",
		value: "agricultureInForest"
	},
	{
		nepali: "आम्दानी र खर्चको पारदर्शिता",
		english: "",
		value: "incomeExpense"
	}
];

const presidentName = { nepali: "अध्यक्षको नाम", english: "" };
const presidentContact = { nepali: "अध्यक्षको सम्पर्क नम्बर", english: "" };

const farmType = { nepali: "किसिम", english: "" };

const policeTitle = { nepali: "सुरक्षा निकाय प्रमुखको", english: "" };
const contact = { nepali: "सम्पर्क नम्बर", english: "" };
const stationType = { nepali: "सुरक्षा निकायको प्रकार", english: "" };
const stationTypeList = [
	{
		nepali: "प्रहरी चौकी",
		english: "Police Station",
		value: "police_station"
	},
	{
		nepali: "ससत्र प्रहरी",
		english: "Armed Police",
		value: "armed_police"
	},
	{
		nepali: "सैनिक ब्यारेक",
		english: "Army Barrack",
		value: "army_barrack"
	},
	{
		nepali: "ट्राफिक बिट",
		english: "Traffic Beat",
		value: "traffic_beat"
	},
	{
		nepali: "सामुदायक प्रहरी",
		english: "Community Police",
		value: "community_police"
	}
];

const clubType = [
	{
		nepali: "फुटबल क्लब",
		english: "",
		value: "footballClub"
	},
	{
		nepali: "बाल समूह",
		english: "",
		value: "childClub"
	},
	{
		nepali: "युथ क्लब / युवा क्लब",
		english: "",
		value: "youthClub"
	},
	{
		nepali: "कृषक समूह",
		english: "",
		value: "farmerClub"
	},
	{
		nepali: "आमा समूह",
		english: "",
		value: "mothersClub"
	},
	{
		nepali: "सामाजिक समूह",
		english: "",
		value: "socialClub"
	},
	{
		nepali: "उधमी समूह",
		english: "",
		value: "businessClub"
	},
	{
		nepali: "वचत समूह",
		english: "",
		value: "savingClub"
	},
	{
		nepali: "व्यवसायिक कृषक समूह",
		english: "",
		value: "farmerBusiness"
	},
	{
		nepali: "परम्परागत समूह",
		english: "",
		value: "traditionalClub"
	}
];

const clubUpdateQn = { nepali: "अध्यावधिक गरिएको", english: "" };

const clubMainWork = {
	nepali: "विगत एक वर्ष भित्र गरिएका  प्रमुख कार्यहरु",
	english: ""
};

const clubTypeQn = { nepali: "समूहको प्रकार", english: "" };

const clubHead = { nepali: "समूहको अध्यक्षको", enlgish: "" };

const templeGroup = { nepali: "सञ्चालनको लागि गुठी", english: "" };

const templeLegend = {
	nepali: " मठ/मन्दिर सम्बन्धि ऐतिहाँसिक किम्वदन्ती",
	english: ""
};

const type = { nepali: "प्रकार", english: "" };

const tourismType = [
	{
		nepali: "प्राकृतिक",
		english: "",
		value: "natural"
	},
	{
		nepali: "मानव निर्मित",
		english: "",
		value: "builtByPeople"
	}
];

const mainBenefit = { nepali: "प्रमुख विशेषता", english: "" };

const institutionType = [
	{
		nepali: "सरकारी",
		english: "",
		value: "government"
	},
	{
		nepali: "अर्ध सरकारी",
		english: "",
		value: "semiGovernment"
	},
	{
		nepali: "गैर सरकारी",
		english: "",
		value: "nonGovernment"
	},
	{
		nepali: "अन्तराष्ट्रिय गैर सरकारी",
		english: "",
		value: "internationalGov"
	},
	{
		nepali: "निजी",
		english: "",
		value: "private"
	}
];

const workingField = { nepali: "काम गर्ने क्षेत्र", english: "" };

const instituteService = { nepali: "उपलब्ध सेवाहरु", english: "" };

const instituteHead = { nepali: "संस्था प्रमुखको ", english: "" };

const bankType = [
	{
		nepali: "बाणिज्य",
		english: "Banijya",
		value: "banijya"
	},
	{
		nepali: "विकास",
		english: "Development",
		value: "development"
	},
	{
		nepali: "लघुवित्त संस्था",
		english: "Finance",
		value: "finance"
	},
	{
		nepali: "सहकारी संस्था",
		english: "Cooperative",
		value: "cooperative"
	}
];

const industryOwnershipList = [
	{
		nepali: "सरकारी",
		english: "Government",
		value: "government"
	},
	{
		nepali: "पब्लिक",
		english: "Public",
		value: "public"
	},
	{
		nepali: "निजि",
		english: "Private",
		value: "private"
	},
	{
		nepali: "सहकारी",
		english: "Cooperative",
		value: "cooperative"
	}
];

const industryTypeList = [
	{
		nepali: "लघु",
		english: "Laghu",
		value: "laghu"
	},
	{
		nepali: "घरेलु",
		english: "Gharelu",
		value: "gharelu"
	},
	{
		nepali: "साना",
		english: "Small",
		value: "Small"
	},
	{
		nepali: "मझौला",
		english: "medium",
		value: "medium"
	},
	{
		nepali: "ठुला",
		english: "large",
		value: "large"
	},
	{
		nepali: "अन्य",
		english: "other",
		value: "other"
	}
];

const buildingType = [
	{
		nepali: "कच्ची",
		english: "",
		value: "kacchi"
	},
	{
		nepali: "पक्की",
		english: "",
		value: "pakki"
	}
];

const buildingUseQn = { nepali: "प्रयोग", english: "" };

const buildingUse = [
	{
		nepali: "भाडामा दिएको",
		english: "",
		value: "rent"
	},
	{
		nepali: "आफैंले प्रयोग गरेको",
		english: "",
		value: "self"
	}
];

const buildingCondition = { nepali: "भवनको अवस्था", english: "" };

const roadTypeLength = { nepali: "सडकको प्रकार र लम्बाई", english: "" };

const kacchiKm = { nepali: "कच्ची किमि", english: "" };
const gravelKm = { nepali: "ग्राभेल किमि", english: "" };
const kaloPatraKm = { nepali: "कालो पत्रे किमि", english: "" };

const wardRoad = { nepali: "सडकले जोडेको वार्डहरु", english: "" };

const workingFrom = { nepali: "सञ्चालन हुने स्थान देखि", english: "" };
const workingTo = { nepali: "सञ्चालन हुने स्थान सम्म", english: "" };
const height = { nepali: "लम्बाई", english: "" };

const capacity = { nepali: "क्षमता", english: "" };

const workingBody = { nepali: "सहयोगी निकाय", english: "" };

const establishDate = { nepali: "स्थापना मिति", english: "" };

const injuredCount = {
	nepali: "घाईते हुनेको संख्या",
	english: ""
};

const deathCount = {
	nepali: "मृत्युहुनेको संख्या",
	english: ""
};

const wariWard = { nepali: "वारी वडा नं.", english: "" };
const pariWard = { nepali: "पारी वडा नं.", english: "" };

const nationWideElectricity = {
	nepali: "राष्ट्रिय प्रसारणमा जोडिएको",
	english: ""
};

const electricityType = [
	{
		nepali: "जलविद्युत योजना",
		english: "",
		value: "planning"
	},
	{
		nepali: "पेल्ट्रिक सेट",
		english: "",
		value: "peltrickSet"
	},
	{
		nepali: "लघु जलविद्युत",
		english: "",
		value: "smallHydroPower"
	}
];

const towerType = [
	{
		nepali: "एन.टि.सी.",
		english: "",
		value: "ntc"
	},
	{
		nepali: "एन.सेल",
		english: "",
		value: "ncell"
	},
	{
		nepali: "स्काई",
		english: "",
		value: "sky"
	},
	{
		nepali: "स्मार्ट सेल",
		english: "",
		value: "smartCell"
	},
	{
		nepali: "अन्य",
		english: "",
		value: "other"
	}
];

const isDamageByTower = {
	nepali: "टावरको कारणले समुदायमा पुगेको क्षति",
	english: ""
};

const isReconstruct = { nepali: "नियमित मर्मत सम्भारको व्यवस्था	", english: "" };
const isWater = { nepali: "आवश्यक पानीको व्यवस्था ", english: "" };
const isHygiene = { nepali: "दैनिक सफा सुग्घर गर्ने व्यवस्था", english: "" };
const isToilet = { nepali: "सार्वजनिक शौंचालयको व्यवस्था", english: "" };
const isWaterInPark = { nepali: "खानेपानीको  व्यवस्था", english: "" };

const isStableNetwork = { nepali: "वाईफाईको सेवा सुविधा भरपर्दो", english: "" };

const isExcavation = { nepali: "खानी उत्खनन भएको", english: "" };

const isPollution = { nepali: "खानी बाट वातावरण प्रदुषण", english: "" };

const wasAccident = {
	nepali: "ट्रान्समिसन लाईनले दुर्र्घटना भएको",
	english: ""
};

const addressFromTo = {
	nepali: "ट्रान्समिसन लाईन भएको क्षेत्र (ठेगाना)",
	english: ""
};

const from = { nepali: "देखि", english: "" };
const to = { nepali: "सम्म", english: "" };

const isGivenToStay = { nepali: "सरकारी बासस्थान उपलब्ध", english: "" };

const districtListening = { nepali: "एफ.एम. सुनिने जिल्लाहरु", english: "" };
const fmRange = { nepali: "रेन्ज", english: "" };
const perPublication = { nepali: "प्रकाशन प्रति", english: "" };
const officeLocation = { nepali: "कार्यालय रहेको स्थान", english: "" };
const newsPaperType = [
	{
		nepali: "दैनिक",
		english: "",
		value: "daily"
	},
	{
		nepali: "साप्ताहिक",
		english: "",
		value: "weekly"
	},
	{
		nepali: "पाक्षिक",
		english: "",
		value: "fortnightly"
	},
	{
		nepali: "मासिक",
		english: "",
		value: "yearly"
	}
];

const industryLocation = { nepali: "उद्योग रहेको स्थान", english: "" };
const mainProduction = { nepali: "प्रमुख उत्पादन", english: "" };
const mainSalePlace = { nepali: "प्रमुख विक्रि स्थल", english: "" };
const industryType = { nepali: "उद्योगको प्रकार", english: "" };
const industryOwnership = { nepali: "उद्योगको स्वामित्व", english: "" };
const employmentProvide = {
	nepali: "उद्योगले दिएको रोजगारी संख्या",
	english: ""
};

const marketTypeQn = { nepali: "हाटबजारको किसिम", english: "" };

const marketOpenDayQn = { nepali: "हाटबजार लाग्ने दिन", english: "" };

const marketOpenDay = [
	{
		nepali: "आइतबार ",
		english: "Sunday",
		value: "sunday"
	},
	{
		nepali: "सोमबार ",
		english: "Monday",
		value: "monday"
	},
	{
		nepali: "मंगलबार ",
		english: "Tuesday",
		value: "tuesday"
	},
	{
		nepali: "बुधबार",
		english: "Wednesday",
		value: "wednesday"
	},
	{
		nepali: "बिहिबार",
		english: "Thursday",
		value: "thursday"
	},
	{
		nepali: "शुक्रबार",
		english: "Friday",
		value: "friday"
	},
	{
		nepali: "शनिबार",
		english: "Saturday",
		value: "saturday"
	}
];

const mainSaleProduct = { nepali: "कारोवार हुने प्रमुख वस्तुहरु", english: "" };

const providedSerivce = {
	nepali: "हाट बजारले सेवा दिएको क्षेत्र",
	english: ""
};

const groundTypeQn = { nepali: "खेल मैदानको किसिम	", english: "" };
const groundType = [
	{
		nepali: "भलिबल",
		english: "VolleyBall",
		value: "volleyball"
	},
	{
		nepali: "फुटबल",
		english: "FootBall",
		value: "football"
	},
	{
		nepali: "अन्य",
		english: "Other",
		value: "other"
	}
];

const riskArea = { nepali: "जोेखिम भएका क्षेत्रहरु", english: "" };

const effectFromDisasterQn = {
	nepali: "सम्भावित जोखिमबाट भएका असरहरु (विगत ३ वर्षको)	",
	english: ""
};

const effectFromDisasters = [
	{
		nepali: "बाढि",
		english: "Flood",
		value: "flood"
	},
	{
		nepali: "पहिरो",
		english: "Landslide",
		value: "landslide"
	},
	{
		nepali: "जनावर आतंक",
		english: "Animal Terror",
		value: "animalTerror"
	},
	{
		nepali: "हिमताल विस्फोटन",
		english: "Iceberg Explosion",
		value: "iceberg"
	},
	{
		nepali: "डुवान",
		english: "Drowning",
		value: "drowning"
	},
	{
		nepali: "आगलागि",
		english: "Fire",
		value: "fire"
	},
	{
		nepali: "सर्प दंश",
		english: "Snake Sting",
		value: "snakeSting"
	},
	{
		nepali: "चट्याङ",
		english: "Lightning",
		value: "lightning"
	},
	{
		nepali: "खडेरी",
		english: "Drought",
		value: "drought"
	}
];

const effectofDeath = {
	nepali: "मृत्यु",
	english: "Death",
	value: "death"
};
const effectofInjured = {
	nepali: "घाइते",
	english: "Injured",
	value: "injured"
};

const possibleRisk = { nepali: "सम्भावित जोखिमहरु", english: "" };

const festivalName = { nepali: "मेला, चाडपर्व तथा जात्रा", english: "" };

const festivalMonthsQn = { nepali: "मनाउने महिना", english: "" };

const celebrateReligion = { nepali: "मनाउने जातजाती", english: "" };

const festivalAttribute = {
	nepali: "मेला, चाडपर्व तथा जात्राको विशेषता",
	english: ""
};

const festivalMonths = [
	{
		nepali: "बैशाख",
		english: "Baishakh",
		value: "baishakh"
	},
	{
		nepali: "जेष्ठ ",
		english: "Jestha",
		value: "jestha"
	},
	{
		nepali: "असार",
		english: "Asar",
		value: "asar"
	},
	{
		nepali: "श्रावण",
		english: "Srawan",
		value: "srawan"
	},
	{
		nepali: "भदौ",
		english: "Bhadau",
		value: "bhadau"
	},
	{
		nepali: "असोज",
		english: "Asoj",
		value: "asoj"
	},
	{
		nepali: "कार्तिक",
		english: "Kartik",
		value: "kartik"
	},
	{
		nepali: "मंसिर",
		english: "Mangsir",
		value: "mangsir"
	},
	{
		nepali: "पौष",
		english: "Poush",
		value: "poush"
	},
	{
		nepali: "माघ",
		english: "Magh",
		value: "magh"
	},
	{
		nepali: "फाल्गुन",
		english: "Falgun",
		value: "falgun"
	},
	{
		nepali: "चैत्र",
		english: "Chaitra",
		value: "chaitra"
	}
];

const electionParty = { nepali: "निर्वाचित पार्टी", english: "" };

const department = { nepali: "शाखा", english: "" };

const remarks = { nepali: "कैफियत", english: "" };

module.exports = {
	industryOwnershipList,
	industryTypeList,
	department,
	remarks,
	electionParty,
	festivalName,
	festivalMonthsQn,
	celebrateReligion,
	festivalAttribute,
	festivalMonths,
	possibleRisk,
	riskArea,
	effectFromDisasterQn,
	effectFromDisasters,
	effectofDeath,
	effectofInjured,
	groundTypeQn,
	groundType,
	marketTypeQn,
	marketOpenDayQn,
	marketOpenDay,
	providedSerivce,
	mainSaleProduct,
	industryLocation,
	mainProduction,
	mainSalePlace,
	industryType,
	industryOwnership,
	employmentProvide,
	perPublication,
	officeLocation,
	newsPaperType,
	districtListening,
	fmRange,
	isGivenToStay,
	addressFromTo,
	from,
	to,
	wasAccident,
	isExcavation,
	isPollution,
	isStableNetwork,
	isToilet,
	isWaterInPark,
	isHygiene,
	isReconstruct,
	isWater,
	towerType,
	isDamageByTower,
	electricityType,
	nationWideElectricity,
	wariWard,
	pariWard,
	injuredCount,
	deathCount,
	establishDate,
	workingBody,
	workingFrom,
	workingTo,
	height,
	capacity,
	kacchiKm,
	gravelKm,
	kaloPatraKm,
	wardRoad,
	roadTypeLength,
	buildingType,
	buildingUseQn,
	buildingUse,
	buildingCondition,
	bankType,
	institutionType,
	workingField,
	instituteHead,
	instituteService,
	type,
	tourismType,
	mainBenefit,
	templeLegend,
	templeGroup,
	clubTypeQn,
	clubHead,
	clubMainWork,
	clubUpdateQn,
	clubType,
	stationType,
	stationTypeList,
	contact,
	policeTitle,
	farmType,
	fieldType,
	name,
	categoryTitle,
	category,
	establish,
	description,
	member,
	address,
	phone,
	clubActive,
	designation,
	count,
	latitude,
	longitude,
	wardQuestion,
	photo,
	specName,
	specValue,
	add,
	remove,
	darta,
	itemTitle,
	area,
	kachiCount,
	pakkiCount,
	kachiRoomCount,
	pakkiRoomCount,
	gharTitle,
	roomTitle,
	benchCapacity,
	staffTitle,
	ground,
	compound,
	childplayground,
	toilet,
	hastoilet,
	maleCount,
	femaleCount,
	staffCount,
	hasdrinking,
	appointAs,
	staff,
	student,
	schoolClass,
	malestudent,
	femalestudent,
	website,
	email,
	schooltype,
	schoollevel,
	hotelType,
	specTitle,
	position,
	emergencyNo,
	instituteType,
	buildingCount,
	consumedUser,
	vehicleTitle,
	memberTitle,
	instituteTitle,
	schoolLevel,
	categoryList,
	suffixCategoryList,
	fiscal,
	budget,
	dartachalani,
	effectedHouse,
	pdf,
	timespan,
	studentProd,
	staffProd,
	targetedCategory,
	helper,
	deleteMsg,
	agree,
	disagree,
	studentLevel,
	cberga,
	schoolFacility,
	schoolFacilityTitle,
	schoolFacilityQn,
	classNineEducation,
	classNineEducationYes,
	furniture,
	facultyQn,
	collegelevel,
	collegeSubject,
	teacher,
	teacherMale,
	teacherFemale,
	healthInfo,
	healthTitle,
	healthFacility,
	healthFacilityTitle,
	healthLabel,
	shayaCountQn,
	vaccineTitle,
	vaccineQn,
	vaccineList,
	teacherTitleList,
	staffTitleList,
	fiscalYear,
	vegetationAnimal,
	grazingTypeQn,
	grazingType,
	mainAnimalQn,
	forestInfoQn,
	forestInfoList,
	presidentName,
	presidentContact,
	dead,
	injured
};
