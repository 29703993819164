import {
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
	Grid
} from "@material-ui/core";
import { green, lightBlue, red } from "@material-ui/core/colors";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import classnames from "classnames";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import isEmpty from "../../../utils/validation/is-empty";
import React, { Component } from "react";
import FullScreenDialog from "../../common/frequent/dialog/FullScreen";
import TablePaginationActionsWrapped from "../search/death/TablePaginationActions";
import ClipLoader from "react-spinners/ClipLoader";
import GetHouseDetail from "../search/advancedHouseSearch/houseNumber/GetHouseDetail";
import {
	clearHouseDetail,
	getHouseByHouseId
} from "../../../actions/searchAction";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { CSVLink } from "react-csv";
import { Button } from "antd";
class ListHouse extends Component {
	state = {
		page: 0,
		house: {},
		rowsPerPage: 100,
		houseOpen: false
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = event => {
		this.setState({ page: 0, rowsPerPage: event.target.value });
	};
	clearFilter = () => {
		this.setState({
			sn: "",
			houseNumber: "",
			wardNumber: "",
			contactNumber: ""
		});
	};

	onHouseNumberClick = hn => {
		this.setState({ houseOpen: true, houseNumber: hn }, () => {
			this.props.getHouseByHouseId(hn);
		});
	};

	handleCloseHouse = name => {
		this.setState({ [name]: false, house: {} });
	};

	componentDidUpdate(prevProps) {
		if (prevProps.house !== this.props.house) {
			this.setState({
				house: this.props.house
			});
		}
	}

	render() {
		const {
			classes,
			houses,
			handleChange,
			houseNumber,
			wardNumber,
			ownerName
		} = this.props;

		// const { houses, csvData } = this.state;
		const { page, rowsPerPage } = this.state;
		return (
			<Table className={classes.table}>
				<TableHead>
					<TableRow>
						<TableCell>
							<Typography>क्र.स</Typography>
							{/* <TextField
								className={classes.textFieldSN}
								id="outlined-sn"
								label={"क्र.श"}
								name="sn"
								value={sn}
								type="number"
								onChange={handleChange}
								variant="outlined"
							/> */}
						</TableCell>
						<TableCell>
							<TextField
								id="outlined-houseNumber"
								label={"घर नम्बर"}
								className={classes.textFieldHN}
								name="houseNumber"
								value={houseNumber}
								type="number"
								onChange={handleChange}
								margin="normal"
								variant="outlined"
							/>
						</TableCell>
						<TableCell>
							<TextField
								id="outlined-wardNumber"
								label={"वडा न."}
								className={classes.textFieldWN}
								name="wardNumber"
								value={wardNumber}
								type="number"
								onChange={handleChange}
								margin="normal"
								variant="outlined"
							/>
						</TableCell>
						<TableCell>
							<TextField
								id="outlined-ownerName"
								label={"घरमुलीको नाम"}
								className={classes.textFieldON}
								name="ownerName"
								value={ownerName}
								onChange={handleChange}
								margin="normal"
								variant="outlined"
							/>
						</TableCell>
						{/* <TableCell>
							<TextField
								id="outlined-contactNumber"
								label={"मोबाइल न."}
								className={classes.textFieldCN}
								name="contactNumber"
								value={contactNumber}
								type="number"
								onChange={handleChange}
								margin="normal"
								variant="outlined"
							/>
						</TableCell> */}
						<TableCell>Collector Code</TableCell>
						<TableCell>
							<Typography variant="h6">कृयाकलाप</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{houses &&
						houses
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((house, id) => {
								return (
									<TableRow key={id}>
										<TableCell
											scope="row"
											style={{ padding: "0px !important" }}
										>
											<Typography variant="body1">
												{getNepaliNumber(page * rowsPerPage + (id + 1))}
											</Typography>
										</TableCell>
										<TableCell
											style={{ padding: "0px !important" }}
											align="left"
										>
											<Typography variant="body1">
												{house &&
													house.house &&
													house.house.houseNumber &&
													getNepaliNumber(house.house.houseNumber)}
											</Typography>
										</TableCell>
										<TableCell
											style={{ padding: "0px !important" }}
											align="left"
										>
											<Typography variant="body1">
												{house &&
													house.house &&
													house.house.wardNumber &&
													getNepaliNumber(house.house.wardNumber)}
											</Typography>
										</TableCell>
										<TableCell
											style={{ padding: "0px !important" }}
											align="left"
										>
											<Typography variant="body1">{house.fullName}</Typography>
										</TableCell>
										{/* <TableCell
											style={{ padding: "0px !important" }}
											align="left"
										>
											{typeof house.contactNumber !== undefined &&
												house.contactNumber > 100000 && (
													<Typography variant="body1">
														{house &&
															house.house &&
															house.house.contactNumber &&
															getNepaliNumber(house.contactNumber)}
													</Typography>
												)}
										</TableCell> */}
										<TableCell>{house.house.dataCollectionCode}</TableCell>
										<TableCell
											style={{ padding: "0px !important" }}
											align="left"
										>
											<VisibilityIcon
												onClick={() =>
													this.onHouseNumberClick(
														house && house.house && house.house._id
													)
												}
												className={classnames(classes.preview, classes.icon)}
											/>
											{house.house && (
												<Link
													to={`/survey-by-house/edit/${house.house.houseNumber}`}
												>
													<Icon
														className={classnames(classes.edit, classes.icon)}
													/>
												</Link>
											)}
											<DeleteIcon
												className={classnames(classes.delete, classes.icon)}
											/>
										</TableCell>
									</TableRow>
								);
							})}
				</TableBody>
				{houses && (
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
								colSpan={7}
								count={houses.length}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									native: true
								}}
								onChangePage={this.handleChangePage}
								onChangeRowsPerPage={this.handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActionsWrapped}
							/>
						</TableRow>
					</TableFooter>
				)}
				{this.state.houseOpen && (
					<FullScreenDialog
						name="houseOpen"
						open={this.state.houseOpen}
						title={`House Detail `}
						handleClose={this.handleCloseHouse}
					>
						<Grid container spacing={24}>
							{this.props.load ? (
								<Grid
									style={{ marginTop: "40vh" }}
									container
									direction="row"
									justify="center"
									alignItems="center"
								>
									<Grid item>
										<ClipLoader
											sizeUnit={"px"}
											size={84}
											color={"#00ACC1"}
											loading={this.props.load}
										/>
									</Grid>
								</Grid>
							) : (
								<Grid
									container
									direction="row"
									justify="center"
									alignItems="flex-start"
								>
									{this.state.house && (
										<Grid item xs={9}>
											<GetHouseDetail
												houseNumber={
													this.state.house &&
													this.state.house.houseDetails &&
													this.state.house.houseDetails.houseNumber
												}
												house={this.state.house}
											/>
										</Grid>
									)}
								</Grid>
							)}
						</Grid>
					</FullScreenDialog>
				)}
			</Table>
		);
	}
}

const styles = theme => ({
	textFieldSN: {
		width: 75
	},
	textFieldHN: {
		width: theme.spacing.unit * 12
	},
	textFieldWN: { width: theme.spacing.unit * 12 },
	textFieldON: { width: theme.spacing.unit * 30 },
	textFieldCN: { width: theme.spacing.unit * 15 },
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0"
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF"
		}
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1"
		}
	},
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3
	},
	table: {
		minWidth: 500
	},
	tableWrapper: {
		overflowX: "auto"
	},
	fab: {
		float: "right"
	},
	icon: {
		margin: theme.spacing.unit * 1,
		cursor: "pointer"
	},
	preview: {
		color: lightBlue[500]
	},
	edit: {
		color: green[700]
	},
	delete: {
		color: red[500]
	}
});

const mapStateToProps = ({ search: { house, load } }) => ({
	house,
	load
});
export default withStyles(styles)(
	connect(mapStateToProps, { clearHouseDetail, getHouseByHouseId })(ListHouse)
);
