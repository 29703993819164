import React, { Component } from "react";
import { Checkbox, Row, Col } from "antd";
import Typography from "@material-ui/core/Typography";
import {
	Grid,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails
} from "@material-ui/core";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import {
	ward,
	gaupalika,
	selectedLanguage,
	infastructure,
	disaster
} from "../../../variable/global";

export default class DatasetOption extends Component {
	state = {
		index: -1,
		activeIndex: 0,
		expanded: "administrative"
	};

	handleExpansion = panel => (event, expanded) => {
		this.setState({
			expanded: expanded ? panel : false
		});
	};

	handleChange = event => {
		this.props.handleChange(event.target.name, event.target.checked);
	};

	render() {
		const {
			wardSelected,
			infastructureSelected,
			disasterSelected,
			wardCheckAll,
			indeterminateWard,
			boundaryChecked,
			otherCheckbox
		} = this.props;

		return (
			<div>
				<ExpansionPanel
					style={{ padding: 0, backgroundColor: "transparent" }}
					expanded={this.state.expanded === `administrative`}
					onChange={this.handleExpansion(`administrative`)}
				>
					<ExpansionPanelSummary expandIcon={<ExpandIcon />}>
						{" "}
						<Typography
							variant="body2"
							style={{ fontWeight: 500 }}
							gutterBottom
						>
							Administrative Data
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Grid conatiner direction="column">
							<Checkbox
								name="boundary"
								onChange={this.handleChange}
								checked={otherCheckbox.boundary}
							>
								{gaupalika[selectedLanguage]} सिमाना
							</Checkbox>
							<Checkbox
								indeterminate={indeterminateWard}
								onChange={this.props.onCheckAllWardChange}
								checked={wardCheckAll}
							>
								{gaupalika[selectedLanguage]}
							</Checkbox>
							<Checkbox.Group
								style={{ width: "100%" }}
								onChange={this.props.handleWardChange}
								value={wardSelected}
							>
								{ward.map(each => (
									<Col key={each.value}>
										<Checkbox value={each.value}>
											{each[selectedLanguage]}
										</Checkbox>
									</Col>
								))}
							</Checkbox.Group>
						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>
				<ExpansionPanel
					style={{ padding: 0, backgroundColor: "transparent" }}
					expanded={this.state.expanded === `infastructure`}
					onChange={this.handleExpansion(`infastructure`)}
				>
					<ExpansionPanelSummary expandIcon={<ExpandIcon />}>
						{" "}
						<Typography
							variant="body2"
							style={{ fontWeight: 500 }}
							gutterBottom
						>
							Infastructure Data
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Grid container direction="column">
							{infastructure.map(each => (
								<Checkbox
									name={each.value}
									onChange={this.handleChange}
									checked={otherCheckbox[each.value]}
								>
									{each[selectedLanguage]}
								</Checkbox>
							))}
						</Grid>

						{/* <Checkbox.Group
							style={{ width: "100%" }}
							onChange={this.props.handleInfastructureChange}
							value={infastructureSelected}
						>
							{infastructure.map(each => (
								<Col key={each.value}>
									<Checkbox value={each.value}>
										{each[selectedLanguage]}
									</Checkbox>
								</Col>
							))}
						</Checkbox.Group> */}
					</ExpansionPanelDetails>
				</ExpansionPanel>
				<ExpansionPanel
					style={{ padding: 0, backgroundColor: "transparent" }}
					expanded={this.state.expanded === `disaster`}
					onChange={this.handleExpansion(`disaster`)}
				>
					<ExpansionPanelSummary expandIcon={<ExpandIcon />}>
						{" "}
						<Typography
							variant="body2"
							style={{ fontWeight: 500 }}
							gutterBottom
						>
							Natural Disaster
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Checkbox.Group
							style={{ width: "100%" }}
							onChange={this.props.handleDisasterChange}
							value={disasterSelected}
						>
							{disaster.map(each => (
								<Col key={each.value}>
									<Checkbox value={each.value}>
										{each[selectedLanguage]}
									</Checkbox>
								</Col>
							))}
						</Checkbox.Group>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</div>
		);
	}
}
