import axios from "axios";
import {
  SAVE_INSTITUTE,
  SAVE_TOURISM,
  SAVE_CLUB,
  SAVE_ITEM,
  SAVE_SCHOOL,
  SHOW_DATA,
  SAVE_PROJECT,
  SAVE_PRODEDUCATION
} from "./types";
import { startSnackBar } from "../actions/utilAction";

export const addInstituteSurvey = data => dispatch => {
  axios.post(`/api/institute/`, data).then(res => {
    dispatch({
      type: SAVE_INSTITUTE,
      payload: res.data
    });
  });
};

export const addInstitutionSurvey = (
  route,
  data,
  history,
  redirectUrl
) => dispatch => {
  return axios
    .post(`/api/institute/${route}/`, data)
    .then(res => {
      history.push(`/survey-by-institution/show/${redirectUrl}`);

      return dispatch({
        type: SAVE_INSTITUTE,
        payload: res.data
      });
    })

    .catch(error => {
      if (Array.isArray(error.response.data)) {
        dispatch(
          startSnackBar({
            open: true,
            variant: "error",
            message: error.response.data
          })
        );
      }
    });
};

export const addSchoolSurvey = data => dispatch => {
  console.log("school", data);
  axios.post(`/api/school/`, data).then(res => {
    dispatch({
      type: SAVE_SCHOOL,
      payload: res.data
    });
  });
};

export const addTourismSurvey = data => dispatch => {
  axios.post(`/api/place/`, data).then(res => {
    dispatch({
      type: SAVE_TOURISM,
      payload: res.data
    });
  });
};

export const addClubSurvey = data => dispatch => {
  axios.post(`/api/club/`, data).then(res => {
    dispatch({
      type: SAVE_CLUB,
      payload: res.data
    });
  });
};

export const addItemSurvey = data => dispatch => {
  axios.post(`/api/item/`, data).then(res => {
    dispatch({
      type: SAVE_ITEM,
      payload: res.data
    });
  });
};

export const addProdEducationSurvey = data => dispatch => {
  axios.post(`/api/prodEducation/`, data).then(res => {
    dispatch({
      type: SAVE_PRODEDUCATION,
      payload: res.data
    });
  });
};

export const deleteInstitute = (category, id) => {
  return axios
    .delete(`/api/${category}/delete/${id}`)
    .then(res => {
      console.log("res", res.data);
      return Promise.resolve(true);
    })
    .catch(err => {
      return Promise.reject(false);
    });
};

export const addProjectSurvey = data => dispatch => {
  axios.post(`/api/project/`, data).then(res => {
    dispatch({
      type: SAVE_PROJECT,
      payload: res.data
    });
  });
};

export const deleteProject = (category, id) => {
  return axios
    .delete(`/api/${category}/delete/${id}`)
    .then(res => {
      return Promise.resolve(true);
    })
    .catch(err => {
      return Promise.reject(false);
    });
};

export const viewInstituteSurvey = (data, cat) => dispatch => {
  axios.post(`/api/${cat}/all`, { category: data }).then(res => {
    dispatch({
      type: SHOW_DATA,
      payload: res.data
    });
  });
};
