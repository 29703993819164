import React, { Fragment } from "react";
import { Spin } from "antd";
// import { useSelector } from "react-redux";

export default function Index({ spinning, children }) {
	return (
		<Spin
			tip={`Loading...`}
			spinning={spinning}
			size="large"
			// style={{ width: "100%", height: "100vh" }}
		>
			{children}
		</Spin>
	);
}
