import {
  Fab,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import PrintIcon from "@material-ui/icons/Print";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import {
  clearHouseDetail,
  getFamilyById,
  getHouseByHouseNumber,
  advanceCustomSearch,
} from "../../../../../actions/searchAction";
import getNepaliNumber from "../../../../../utils/getNepaliNumber";
import {
  ageCategory,
  ageDiff,
  family,
  female,
  male,
  selectedLanguage,
  thirdgender,
  total,
} from "../../../../../variable/global";
// import Table from "../../../../common/Table/Table.jsx";
import Card from "../../../../common/Card/Card.jsx";
import CardBody from "../../../../common/Card/CardBody.jsx";
import CardHeader from "../../../../common/Card/CardHeader.jsx";
import FullScreenDialog from "../../../../common/frequent/dialog/FullScreen";
import EachMember from "../../../../common/frequent/FamilyMember";
import ReactToPrint from "../../../../common/frequent/print/ReactToPrint.jsx";
import GetHouseDetail from "../houseNumber/GetHouseDetail";
import TablePaginationActionsWrapped from "./TablePagination";
import ConstructMessage from "../../../sms/message/ConstructMessage";

class NamePagination extends Component {
  constructor(props) {
    super(props);
    props.clearHouseDetail();
  }
  state = {
    familyMemberOpen: false,
    member: {},
    houseOpen: false,
    house: {},
    houseNumber: null,
    beforePrint: false,
    component: null,
    sendSmsOpen: false,
    contactNumber: [],
  };
  handleAfterPrint = () => {
    this.setState({ beforePrint: false });
  };

  handleBeforePrint = () => {
    this.setState({ beforePrint: true });
  };

  getContent = () => {
    return this.setState(
      { beforePrint: true, component: this.componentRef },
      () => {
        return this.state.component;
      }
    );
  };
  getGender = (gender) => {
    if (gender === "female") return "महिला";
    else if (gender === "male") return "पुरुष";
    else return "तेश्रो लिंगी";
  };

  onNameClick = (event) => {
    const id = event.target.id;
    this.setState({ familyMemberOpen: true }, () => {
      this.props.getFamilyById(id);
    });
  };

  onHouseNumberClick = (event) => {
    const houseNumber = event.target.id;
    this.setState({ houseOpen: true, houseNumber }, () => {
      this.props.getHouseByHouseNumber(houseNumber);
    });
  };

  componentDidMount() {
    this.getContactApi();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.member !== this.props.member) {
      this.setState({
        member: this.props.member,
      });
    }

    if (prevProps.house !== this.props.house) {
      this.setState({
        house: this.props.house,
      });
    }

    if (prevProps.rows !== this.props.rows) {
      this.getContactApi();
    }
  }

  handleClose = (name) => {
    this.setState({ [name]: false });
  };

  handleClickOpen = (name) => {
    this.setState({ [name]: true });
  };

  handleCloseMember = (name) => {
    this.setState({ [name]: false, member: {} });
  };

  handleCloseHouse = (name) => {
    this.setState({ [name]: false, house: {} });
  };

  mapRelation = (relation) => {
    if (relation === "self_house_own") return "घरमुली आफै";
    if (relation === "husband") return "श्रीमान";
    if (relation === "wife") return "श्रीमती";
    if (relation === "mother") return "आमा";
    if (relation === "father") return "बुवा";
    if (relation === "son") return "छोरा";
    if (relation === "daughter") return "छोरी";
    if (relation === "buhari") return "बुहारी";
    if (relation === "sashu") return "सासु";
    if (relation === "sasura") return "ससुरा";
    if (relation === "kaka") return "काका";
    if (relation === "kaki") return "काकी";
    if (relation === "fupu") return "फुपू";
    if (relation === "fupaju") return "फुपाजु";
    if (relation === "mama") return "मामा";
    if (relation === "maiju") return "माइजु";
    if (relation === "nati") return "नाती";
    if (relation === "natini") return "नातिनी";
    if (relation === "daju") return "दाजु";
    if (relation === "bhai") return "भाई";
    if (relation === "didi") return "दिदि";
    if (relation === "bahini") return "बहिनि";
    if (relation === "bhauju") return "भाउजु";
    if (relation === "bhai_buhari") return "भाई बुहारी";
    if (relation === "jethan") return "जेठान";
    if (relation === "thulo_bbuwa") return "ठुलो बुवा";
    if (relation === "thulo_aama") return "ठुलो आमा";
    if (relation === "grand_father") return "हजुर बुवा";
    if (relation === "grand_mother") return "हजुर आमा";
    if (relation === "other") return "अन्य";
  };

  getGenderIndex = (gender) =>
    gender === "male" ? 0 : gender === "female" ? 1 : 2;

  getAgeIndex = (age) => (age < 75 ? parseInt(age / 5) : 15);

  // age > 3 && age % 5 === 0 ? parseInt(age / 4) - 1 : parseInt(age / 4);

  getEmptyArray = (row, col) => {
    // let data1 = [...Array(row).keys()].fill([...Array(col).keys()].fill(0));
    let data = [...Array(row).keys()].map((each) =>
      [...Array(col).keys()].map((each) => 0)
    );
    console.log(data);
    // console.log(data1);
    return data;
  };

  getMinMax = () => {
    let row = 16;
    let col = 3;
    let data = this.getEmptyArray(row, col);
    // console.log("empty data", data);
    // return data;
    // let ageDifferences = [[0,4],[4,8]]

    this.props.rows.filter(({ age, gender }, i) => {
      let ageIndex = this.getAgeIndex(age);

      let genderIndex = this.getGenderIndex(gender);

      // if (i < 5) console.log("age", age, ageIndex);
      // if (i < 5) console.log("gender", gender, genderIndex);
      // if (data[ageIndex] === undefined) data[ageIndex] = [];
      data[ageIndex][genderIndex] = data[ageIndex][genderIndex] + 1;
    });
    return data;
  };

  getFullScreen = () => {
    const phoneNumbers =
      this.props.query.abroad || this.props.query.age
        ? this.getAbroadNumbers()
        : this.getPhoneNumbers(this.props.rows);
    // const phoneNumbers = this.getAbroadNumbers();
    this.setState({ sendSmsOpen: true, contactNumber: phoneNumbers });
  };

  isValidPhone = (phoneNumber) =>
    phoneNumber &&
    phoneNumber.toString().length === 10 &&
    phoneNumber.toString().startsWith("98");

  getContactApi = () => {
    let houseId = this.props.rows.map((each) => {
      return each.house._id;
    });
    let result = {
      houseId: houseId,
      query: this.props.query,
    };
    this.props.advanceCustomSearch(result);
  };

  getAbroadNumbers = () => {
    let contacts = this.props.abroadContact
      .filter(
        (contact) =>
          contact.contactNumber && this.isValidPhone(contact.contactNumber)
      )
      .map((contactObj) => contactObj.contactNumber);

    return contacts;
  };

  getPhoneNumbers = (family) => {
    console.log("heelo family");
    var owner = family
      .filter(
        (each) => each.contactNumber && this.isValidPhone(each.contactNumber)
      )
      .map((filterdobj) => filterdobj.contactNumber);
    return owner;
  };

  render() {
    const {
      classes,
      title,
      subtitle,
      tableHeader,
      rows,
      rowsPerPage,
      page,
      handleChangePage,
      handleChangeRowsPerPage,
    } = this.props;

    // const count = this.getTotalCount(rows);

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Grid item>
                    <Typography variant="h6" className={classes.cardTitleWhite}>
                      {title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      className={classes.cardCategoryWhite}
                    >
                      {subtitle}
                    </Typography>
                  </Grid>
                  <Grid item />
                </Grid>

                <Grid item>
                  <Typography variant="body2" className={classes.lengthFamily}>
                    <b>प्रतिफल:</b> {rows.length} {family[selectedLanguage]}
                  </Typography>
                  <Fab
                    color="primary"
                    aria-label="Add"
                    className={classes.fab}
                    onClick={this.getFullScreen}
                  >
                    SMS
                  </Fab>
                  {rows.length !== 0 && (
                    <ReactToPrint
                      style={{ marginTop: 200 }}
                      onBeforePrint={this.handleBeforePrint}
                      onAfterPrint={this.handleAfterPrint}
                      trigger={() => (
                        <Fab
                          variant="extended"
                          aria-label="Delete"
                          className={classes.fab}
                        >
                          <PrintIcon className={classes.printIcon} />
                        </Fab>
                      )}
                      data={this.state.component}
                      content={this.getContent}
                    />
                  )}
                </Grid>
              </Grid>
            </CardHeader>
            {this.state.member &&
              this.state.member.detail &&
              this.state.familyMemberOpen && (
                <FullScreenDialog
                  name="familyMemberOpen"
                  open={this.state.familyMemberOpen}
                  title={`Member detail `}
                  handleClose={this.handleCloseMember}
                >
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                  >
                    {this.state.member && (
                      <Grid item xs={9} className={classes.memberDialog}>
                        <EachMember member={this.state.member} />
                      </Grid>
                    )}
                  </Grid>
                </FullScreenDialog>
              )}

            {this.state.sendSmsOpen && (
              <FullScreenDialog
                name="sendSmsOpen"
                open={this.state.sendSmsOpen}
                title={`Send Sms`}
                handleClose={this.handleClose}
              >
                {rows && (
                  <div className={classes.smsRoot}>
                    <ConstructMessage
                      data={this.state.contactNumber}
                      header={false}
                    />
                  </div>
                )}
              </FullScreenDialog>
            )}

            {this.state.houseOpen && (
              <FullScreenDialog
                name="houseOpen"
                open={this.state.houseOpen}
                title={`House Detail `}
                handleClose={this.handleCloseHouse}
              >
                <Grid container spacing={3}>
                  {this.props.load ? (
                    <Grid
                      style={{ marginTop: "40vh" }}
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <ClipLoader
                          sizeUnit={"px"}
                          size={84}
                          color={"#00ACC1"}
                          loading={this.props.load}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="flex-start"
                    >
                      {this.state.house && (
                        <Grid item xs={9}>
                          <GetHouseDetail
                            houseNumber={this.state.houseNumber}
                            house={this.state.house}
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </FullScreenDialog>
            )}
            <Grid ref={(el) => (this.componentRef = el)}>
              <CardBody>
                {/* Table here */}
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {tableHeader.map((header, key) => (
                        <TableCell key={key}>
                          {header[selectedLanguage]}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.length !== 0 &&
                      rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, id) => (
                          <TableRow key={id}>
                            <TableCell component="th" scope="row">
                              {getNepaliNumber(page * rowsPerPage + (id + 1))}
                            </TableCell>
                            <TableCell align="left">
                              {getNepaliNumber(row.house.wardNumber)}
                            </TableCell>
                            <TableCell align="left">
                              <p
                                className={classes.button}
                                id={row.house.houseNumber}
                                onClick={this.onHouseNumberClick}
                              >
                                {getNepaliNumber(row.house.houseNumber)}
                              </p>
                            </TableCell>
                            <TableCell align="left">
                              <p
                                className={classes.button}
                                id={row._id}
                                onClick={this.onNameClick}
                              >
                                {row.fullName}
                              </p>
                            </TableCell>
                            <TableCell align="left">
                              {this.getGender(row.gender)}
                            </TableCell>
                            <TableCell align="left">
                              {getNepaliNumber(row.age)}
                            </TableCell>
                            <TableCell align="left">
                              {this.mapRelation(row.answererRelation)}
                            </TableCell>
                            <TableCell align="left">
                              {getNepaliNumber(row.contactNumber)}
                            </TableCell>
                          </TableRow>
                        ))}
                    <TableRow />

                    <TableRow>
                      <TableCell>{ageDiff[selectedLanguage]}</TableCell>
                      <TableCell>{male[selectedLanguage]}</TableCell>
                      <TableCell>{female[selectedLanguage]}</TableCell>
                      <TableCell>{thirdgender[selectedLanguage]}</TableCell>
                      <TableCell>{total[selectedLanguage]}</TableCell>
                    </TableRow>

                    {this.getMinMax().map((demo, i) => (
                      <TableRow>
                        <TableCell align="left">
                          {ageCategory[i][selectedLanguage]}
                        </TableCell>
                        <TableCell align="left">
                          {getNepaliNumber(demo[0])}
                        </TableCell>
                        <TableCell align="left">
                          {getNepaliNumber(demo[1])}
                        </TableCell>
                        <TableCell align="left">
                          {getNepaliNumber(demo[2])}
                        </TableCell>
                        <TableCell align="left">
                          {getNepaliNumber(demo.reduce((a, i) => (i += a)))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
                        colSpan={7}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActionsWrapped}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </CardBody>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  button: {
    cursor: "pointer",
    color: "#2196f3",
  },
  memberDialog: {
    marginTop: theme.spacing.unit * 3,
  },
  lengthFamily: {
    color: "white",
    fontSize: "1rem",
  },
});
NamePagination.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({
  search: { member, house, load, abroadContact },
}) => ({
  member,
  house,
  load,
  abroadContact,
});
export default withStyles(styles)(
  connect(mapStateToProps, {
    getFamilyById,
    getHouseByHouseNumber,
    clearHouseDetail,
    advanceCustomSearch,
  })(NamePagination)
);
