import { Grid, IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
	Button,
	Divider,
	Form,
	Icon,
	Input,
	InputNumber,
	message,
	Modal,
	Radio,
	Select,
	Typography,
	Upload,
	Table
} from "antd";
import { getSchool } from "./api.js";
import Axios from "axios";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import {
	add,
	remove,
	address,
	appointAs,
	area,
	benchCapacity,
	childplayground,
	compound,
	fiscalYear,
	email,
	establish,
	femaleCount,
	femalestudent,
	gharTitle,
	ground,
	hasdrinking,
	hastoilet,
	instituteType,
	kachiCount,
	kachiRoomCount,
	latitude,
	longitude,
	maleCount,
	malestudent,
	name,
	pakkiCount,
	pakkiRoomCount,
	phone,
	photo,
	roomTitle,
	schoolClass,
	schoolLevel,
	schoollevel,
	schooltype,
	specName,
	specTitle,
	specValue,
	staff,
	staffCount,
	student,
	toilet,
	wardQuestion,
	website,
	studentLevel,
	cberga,
	schoolFacilityTitle,
	schoolFacility,
	schoolFacilityQn,
	teacher,
	classNineEducation,
	classNineEducationYes,
	furniture,
	staffTitleList,
	teacherTitleList
} from "../../../variable/institute";
import CodeInput from "../../common/codeInput/";
import "../../../fonts/fonts.css";
import ReactCodeInput from "react-verification-code-input";
const { Option } = Select;
const RadioGroup = Radio.Group;
const { TextArea } = Input;

class School extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};

	state = {
		// image upload state
		uniqueIdentifier:
			this.props.match.params.category +
			"_" +
			this.random(10000, 99999) +
			this.random(1000, 9999),
		previewVisible: false,
		previewImage: "",
		fileList: {
			instituteImage: []
		},
		loaded: 0,
		// other
		name: "",
		ward: "",
		benchCapacity: "",
		establishedAt: "",
		phone: "",
		website: "",
		email: "",
		address: "",
		schoolType: "",
		schoolLevel: "",
		latitude: "",
		buildingKacchiCount: "",
		bulidingPakkiCount: "",
		roomKacchiCount: "",
		roomPakkiCount: "",
		longitude: "",
		childPlayGround: "",
		groundArea: "",
		hasToilet: "",
		maleToilet: "",
		femaleToilet: "",
		staffToilet: "",
		maxStaffsIndex: [1],
		maxStudentsIndex: [1],
		maxSpecIndex: 1,
		maxTeachersIndex: [1],
		maxYearIndex: 1,
		fiscalYear: [],
		staffs: [],
		students: [],
		teachers: [],
		facility: [],
		maxFacilityIndex: 1,
		enoughFurniture: "",
		classNineEducation: null,
		classNineMale: "",
		classNineFemale: "",
		classNineSubject: ""
	};

	// image functions uplocad
	handleCancel = () => this.setState({ previewVisible: false });
	capitalizeFirstLetter = string => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};
	handlePreview = file => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true
		});
	};

	// imageUpload
	handleChangeFile = (name, value, imageName) => {
		//name ma house and value ma list of filelist
		this.setState(
			prevState => ({
				...prevState,
				fileList: {
					...prevState.fileList,
					[name]: {
						image: value.fileList,
						fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg"
					}
				}
			}),
			() => {
				if (value.fileList.length > 0) this.handleUpload(name, imageName);
				if (value.fileList.length === 0) this.removeUpload(name, imageName);
			}
		);
	};

	// upload image
	handleUpload = (name, imageName) => {
		const fileList = this.state.fileList[name];
		const data = new FormData();
		const imageObject = fileList.image[0].originFileObj;

		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
		data.append("file", imageObject, fileName);

		Axios.post("/api/imageUpload/upload", data, {
			onUploadProgress: ProgressEvent => {
				this.setState({
					loaded: Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
				});
			}
		})
			.then(res => {
				// then print response status
				message.success("upload successfully.");
				console.log(res.statusText);
			})
			.catch(err => message.error("upload failed."));
	};

	// remove uploaded image
	removeUpload = imageName => {
		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

		Axios.get(`/api/imageUpload/delete/${fileName}`)
			.then(res => {
				// then print response status
				message.success("deleted successfully.");
				console.log(res.statusText);
			})
			.catch(err => message.error("upload failed."));
	};

	handleDateChange = (name, value) => {
		this.setState({ [name]: value });
	};
	componentDidMount() {
		let schoolId = this.props.match.params.id;
		if (schoolId) {
			getSchool(schoolId).then(res =>
				this.setState(
					{
						name: res.data.name,
						address: res.data.address,
						fiscalYear: res.data.fiscalYear,
						establishedAt: res.data.establishedAt,
						website: res.data.website,
						ward: res.data.ward,
						schoolType: res.data.schoolType,
						maxStudentsIndex: res.data.maxStudentIndex,
						maxStaffsIndex: res.data.maxStaffIndex,
						maxTeachersIndex: res.data.maxTeacherIndex,
						maxFacilityIndex: res.data.facility && res.data.facility.length,
						maxYearIndex: res.data.fiscalYear && res.data.fiscalYear.length,
						schoolLevel: res.data.schoolLevel,
						isCberga: res.data.isCberga === true ? "yes" : "no",
						latitude: res.data.geo && res.data.geo.lat,
						longitude: res.data.geo && res.data.geo.lng,
						benchCapacity: res.data.room && res.data.room.benchCapacity,
						students: res.data.student,
						staffs: res.data.staff,
						teachers: res.data.teacher,
						facility: res.data.facility,
						phone: res.data.phone,
						buildingKacchiCount:
							res.data.building && res.data.building.kacchiCount,
						bulidingPakkiCount:
							res.data.building && res.data.building.pakkiCount,
						roomKacchiCount: res.data.room && res.data.room.roomKacchiCount,
						roomPakkiCount: res.data.room && res.data.room.roomPakkiCount,
						childPlayGround:
							res.data.ground && res.data.ground.childPlayGround === true
								? "yes"
								: "no",
						groundArea: res.data.ground && res.data.ground.area,
						hasToilet:
							res.data.toilet && res.data.toilet.hasToilet === true
								? "yes"
								: "no",
						maleToilet: res.data.toilet && res.data.toilet.male,
						femaleToilet: res.data.toilet && res.data.toilet.female,
						staffToilet: res.data.toilet && res.data.toilet.staff,
						enoughFurniture:
							res.data.hasNecessaryFurniture === true ? "yes" : "no",
						classNineEducation:
							res.data.technicalEducation.hasTechnicalSubject === true
								? "yes"
								: "no",
						classNineMale: res.data.technicalEducation.maleStudent,
						classNineFemale: res.data.technicalEducation.femaleStudent,
						classNineSubject: res.data.technicalEducation.subjects,
						email: res.data.email,
						// image
						fileList: {
							school: {
								fileName: res.data.uniqueIdentifier + "_school.jpg",
								image: [
									{
										uid: "-1",
										name: `${res.data.uniqueIdentifier}_school.jpg`,
										status: "done",
										url: `/api/imageUpload/image/${res.data.uniqueIdentifier}_school.jpg`
									}
								]
							}
						}
					},
					() => console.log("in cmds", this.state)
				)
			);
		}
	}

	onSubmit = event => {
		event.preventDefault();
		const newSchool = {
			fiscalYear: this.state.fiscalYear,
			name: this.state.name,
			category: this.props.category,
			uniqueIdentifier: this.state.uniqueIdentifier,
			ward: this.state.ward,
			benchCapacity: this.state.benchCapacity,
			establishedAt: this.state.establishedAt,
			website: this.state.website,
			phone: this.state.phone,
			address: this.state.address,
			schoolType: this.state.schoolType,
			schoolLevel: this.state.schoolLevel,
			email: this.state.email,
			longitude: this.state.longitude,
			latitude: this.state.latitude,
			buildingKacchiCount: this.state.buildingKacchiCount,
			bulidingPakkiCount: this.state.bulidingPakkiCount,
			roomKacchiCount: this.state.roomKacchiCount,
			roomPakkiCount: this.state.roomPakkiCount,
			childPlayGround: this.state.childPlayGround,
			groundArea: this.state.groundArea,
			hasToilet: this.state.hasToilet,
			maleToilet: this.state.maleToilet,
			femaleToilet: this.state.femaleToilet,
			staffToilet: this.state.staffToilet,
			staffs: this.state.staffs,
			students: this.state.students,
			teachers: this.state.teachers,
			facility: this.state.facility,
			enoughFurniture: this.state.enoughFurniture,
			classNineEducation: this.state.classNineEducation,
			classNineMale: this.state.classNineMale,
			classNineFemale: this.state.classNineFemale,
			classNineSubject: this.state.classNineSubject
		};
		if (this.props.isUpdate) {
			newSchool.id = this.props.match.params.id;
			this.props.addInstitutionSurvey(
				"school",
				newSchool,
				this.props.history,
				this.props.match.params.category
			);
		} else
			this.props.addInstitutionSurvey(
				"school",
				newSchool,
				this.props.history,
				this.props.match.params.category
			);
		// this.props.history.push(
		// 	`/survey-by-institution/show/${this.props.match.params.category}`
		// );
	};

	handleChange = event => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	handleSelectChange = (name, value) => {
		this.setState({
			[name]: value
		});
	};

	handleInputChange = name => event => {
		this.setState({
			[name]: event.target.value
		});
	};

	handleReactInputCode = name => value => {
		this.setState({
			[name]: value
		});
	};
	addSpec = () => {
		this.setState(prevState => ({
			maxSpecIndex: prevState.maxSpecIndex + 1
		}));
	};

	addDhikka = (stateName, yearIndex) => () => {
		var maxIndex = `max${this.capitalizeFirstLetter(stateName)}Index`;
		if (yearIndex === undefined) {
			this.setState(prevState => {
				// var students = prevState.students;
				// students.splice(yearIndex + 1, 0, []);
				return {
					[maxIndex]: prevState[maxIndex] + 1,
					maxStudentsIndex: [...(prevState.maxStudentsIndex + 1)],
					// students,
					maxTeachersIndex: [...(prevState.maxTeachersIndex + 1)],
					maxStaffsIndex: [...(prevState.maxStaffsIndex + 1)]
				};
			});
		} else {
			this.setState(prevState => {
				const indexMax = prevState[maxIndex] || [];
				indexMax[yearIndex] = parseInt(parseInt(indexMax[yearIndex] || 0) + 1);
				return { [maxIndex]: indexMax };
			});
		}
	};

	handleNameSpec = index => event => {
		// arr.splice(2, 1, "Lene");
		// event.persist();

		const name = event.target.value;
		this.setState(prevState => {
			const spec = [...prevState.spec];

			const data = {};
			data.name = name;
			data.value = isEmpty(spec[index]) ? "" : spec[index].value;
			spec.splice(index, 1, data);
			return { spec };
		});
	};

	handleValueSpec = index => event => {
		const value = event.target.value;
		this.setState(prevState => {
			const spec = [...prevState.spec];

			const data = {};
			data.value = value;
			data.name = isEmpty(spec[index]) ? "" : spec[index].name;
			spec.splice(index, 1, data);
			return { spec };
		});
	};

	handleMultipleInput = (stateName, year, index, name) => value => {
		// const { value, name } = evt.target;
		this.setState(prevState => {
			const schoolPeople = prevState[stateName];
			if (schoolPeople[year] === undefined) schoolPeople[year] = [];
			if (schoolPeople[year][index] === undefined)
				schoolPeople[year][index] = [];
			const data = { ...schoolPeople[year][index], [name]: value };
			schoolPeople[year].splice(index, 1, data);
			return { [stateName]: schoolPeople };
		});
	};

	removePeoplRow = (stateName, year, index) => () => {
		this.setState(prevState => {
			const schoolPeople = prevState[stateName];
			if (schoolPeople[year] === undefined) schoolPeople[year] = [];
			if (schoolPeople[year][index] === undefined)
				schoolPeople[year][index] = [];
			schoolPeople[year].splice(index, 1);
			// students.splice(index, 1);
			var maxIndex = `max${this.capitalizeFirstLetter(stateName)}Index`;
			const indexMax = prevState[maxIndex] || [];
			indexMax[year] = parseInt(parseInt(indexMax[year] || 1) - 1);
			return { [stateName]: schoolPeople, [maxIndex]: indexMax };
		});
	};

	getRemovedPeopleData = (stateName, year) => {
		var schoolPeople = this.state[stateName];
		// if (schoolPeople[year] === undefined) schoolPeople[year] = [];
		schoolPeople.splice(year, 1);
		return schoolPeople;
	};

	getRemovedPeopleMaxIndex = (stateName, year) => {
		var maxIndex = `max${this.capitalizeFirstLetter(stateName)}Index`;
		var indexMax = this.state[maxIndex] || [];
		indexMax.splice(year, 1);
		return indexMax;
	};

	removeMainDhikka = year => async () => {
		const students = this.getRemovedPeopleData("students", year);
		const maxStudentsIndex = this.getRemovedPeopleMaxIndex("students", year);
		const staffs = this.getRemovedPeopleData("staffs", year);
		const maxStaffsIndex = this.getRemovedPeopleMaxIndex("staffs", year);
		const teachers = this.getRemovedPeopleData("teachers", year);
		const maxTeachersIndex = this.getRemovedPeopleMaxIndex("teachers", year);

		const fiscalYear = this.state.fiscalYear;
		const maxYearIndex = this.state.maxYearIndex;
		fiscalYear.splice(year, 1);

		this.setState({
			maxStudentsIndex,
			students,
			staffs,
			maxStaffsIndex,
			teachers,
			maxTeachersIndex,
			fiscalYear,
			maxYearIndex: maxYearIndex - 1
		});
		// this.removePeopleDhikka("students", year);
		// this.removePeopleDhikka("teachers", year);
		// this.removePeopleDhikka("staffs", year);

		// this.setState(prevState => {
		// const fiscalYear = prevState.fiscalYear;
		// const maxYearIndex = prevState.maxYearIndex
		// fiscalYear.splice(year, 1);
		// 	return { maxYearIndex: maxYearIndex - 1, fiscalYear };

		// });

		// this.removePeopleDhikka("students",year).then(res=> {
		// 	this.setState(prevState => {
		// 		return {  maxYearIndex: prevState.maxYearIndex - 1 };
		// 	});
		// })
	};

	removeSpec = index => () => {
		this.setState(prevState => {
			const spec = [...prevState.spec];
			spec.splice(index, 1);
			return { spec, maxSpecIndex: prevState.maxSpecIndex - 1 };
		});
	};
	getSpecUI = () => {
		const formItemLayoutWithOutLabel = {
			wrapperCol: {
				xs: { span: 24, offset: 0 },
				sm: { span: 20, offset: 4 }
			}
		};
		const { maxSpecIndex, spec } = this.state;
		return (
			<Grid
				container
				item
				xs={12}
				direction="column"
				justify="flex-start"
				alignItems="flex-start"
			>
				{[...Array(maxSpecIndex).keys()].map(each => {
					return (
						<Grid
							container
							direction="row"
							justify="flex-start"
							alignItems="flex-start"
						>
							<Grid xs={5}>
								<Form.Item label={specName[selectedLanguage]}>
									<Input
										name="name"
										type="text"
										value={spec && spec[each] && spec[each].name}
										onChange={this.handleNameSpec(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>
							<Grid xs={5}>
								<Form.Item label={specValue[selectedLanguage]}>
									<Input
										name="value"
										type="text"
										value={spec && spec[each] && spec[each].value}
										onChange={this.handleValueSpec(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>

							{each > 0 && (
								<Grid item>
									<IconButton
										onClick={this.removeSpec(each)}
										disabled={this.state.isView}
									>
										<RemoveIcon style={{ color: "red" }} />
									</IconButton>
								</Grid>
							)}
						</Grid>
					);
				})}
				<Form.Item {...formItemLayoutWithOutLabel}>
					<Button
						type="dashed"
						onClick={this.addSpec}
						disabled={this.state.isView}
					>
						<Icon type="plus" />
						{add[selectedLanguage]}
					</Button>
				</Form.Item>
			</Grid>
		);
	};

	handleFiscalYearChange = year => data => {
		this.setState(prevState => {
			let fiscalYear = prevState.fiscalYear || [];
			fiscalYear[year] = data;
			return { fiscalYear };
		});
	};

	getStudentUI = yearIndex => {
		const { maxStudentsIndex, students } = this.state;
		const columns = [
			{
				title: "S.N",
				dataIndex: "sn",
				key: "sn",
				render: index => <p>{getNepaliNumber(index + 1)}</p>
			},
			{
				title: "Level",
				dataIndex: "sn",
				key: "level",
				render: each => (
					<Select
						placeholder="------------"
						value={
							students &&
							students[yearIndex] &&
							students[yearIndex][each] &&
							students[yearIndex][each].level
						}
						name="level"
						onChange={this.handleMultipleInput(
							"students",
							yearIndex,
							each,
							"level"
						)}
						style={{ minWidth: 100 }}
					>
						{studentLevel.map(level => (
							<Option value={level.value}>{level[selectedLanguage]}</Option>
						))}
					</Select>
				)
			},
			{
				title: "Male",
				dataIndex: "sn",
				key: "male",
				render: each => (
					<InputNumber
						name="male"
						type="number"
						min={0}
						value={
							students &&
							students[yearIndex] &&
							students[yearIndex][each] &&
							students[yearIndex][each].male
						}
						onChange={this.handleMultipleInput(
							"students",
							yearIndex,
							each,
							"male"
						)}
						margin="normal"
						variant="outlined"
					/>
				)
			},
			{
				title: "Female",
				dataIndex: "sn",
				key: "female",
				render: each => (
					<InputNumber
						name="female"
						type="number"
						min={0}
						value={
							students &&
							students[yearIndex] &&
							students[yearIndex][each] &&
							students[yearIndex][each].female
						}
						onChange={this.handleMultipleInput(
							"students",
							yearIndex,
							each,
							"female"
						)}
						margin="normal"
						variant="outlined"
					/>
				)
			},
			{
				title: "Total",
				dataIndex: "sn",
				key: "total",
				render: each => (
					<p>
						{students && students[yearIndex] && students[yearIndex][each]
							? parseInt(students[yearIndex][each].female || 0) +
							  parseInt(students[yearIndex][each].male || 0)
							: 0}
					</p>
				)
			},
			{
				title: "Action",
				key: "action",
				dataIndex: "sn",
				render: index => (
					<Button
						type="dashed"
						onClick={this.removePeoplRow("students", yearIndex, index)}
					>
						<Icon style={{ color: "red" }} type="minus" />
						{remove[selectedLanguage]}
					</Button>
				)
			}
		];

		const dataSource = [...Array(maxStudentsIndex[yearIndex]).keys()].map(
			sn => ({
				sn,
				level: ""
			})
		);
		let total =
			students && students[yearIndex] && students[yearIndex].length > 0
				? students[yearIndex].reduce(
						(acc, cur) => {
							acc.male += parseInt(cur.male || 0);
							acc.female += parseInt(cur.female || 0);
							return acc;
						},
						{ male: 0, female: 0 }
				  )
				: { male: 0, female: 0 };
		return (
			<Grid xs={12}>
				<Form.Item label={fiscalYear[selectedLanguage]}>
					<CodeInput
						fields={4}
						type="number"
						name="fiscalYear"
						// values={[2, 0, 7, 5]}
						values={(() => {
							let data =
								this.state.fiscalYear &&
								this.state.fiscalYear[yearIndex] &&
								this.state.fiscalYear[yearIndex].split("");
							data = data || [0, 0, 0, 0];
							return data;
						})()}
						onChange={this.handleFiscalYearChange(yearIndex)}
					/>
				</Form.Item>
				<Table
					dataSource={dataSource}
					columns={columns}
					footer={() => (
						<p>
							Total Male: {getNepaliNumber(total.male)} and Total female:{" "}
							{getNepaliNumber(total.female)}
						</p>
					)}
					pagination={false}
					title={() => (
						<Button
							type="dashed"
							onClick={this.addDhikka("students", yearIndex)}
						>
							<Icon type="plus" />
							{add[selectedLanguage]}
						</Button>
					)}
				/>
			</Grid>
		);
	};

	getStaffUI = yearIndex => {
		const { maxStaffsIndex, staffs } = this.state;
		const columns = [
			{
				title: "S.N",
				dataIndex: "sn",
				key: "sn",
				render: index => <p>{getNepaliNumber(index + 1)}</p>
			},
			{
				title: "Title",
				dataIndex: "sn",
				key: "appointAs",
				render: each => (
					<Select
						placeholder="------------"
						name="appointAs"
						value={
							staffs &&
							staffs[yearIndex] &&
							staffs[yearIndex][each] &&
							staffs[yearIndex][each].appointAs
						}
						onChange={this.handleMultipleInput(
							"staffs",
							yearIndex,
							each,
							"appointAs"
						)}
						style={{ minWidth: 100 }}
					>
						{staffTitleList.map(level => (
							<Option value={level.value}>{level[selectedLanguage]}</Option>
						))}
					</Select>
				)
			},
			{
				title: "Male Staff",
				dataIndex: "sn",
				key: "male",
				render: each => (
					<InputNumber
						name="male"
						type="number"
						min={0}
						value={
							staffs &&
							staffs[yearIndex] &&
							staffs[yearIndex][each] &&
							staffs[yearIndex][each].male
						}
						onChange={this.handleMultipleInput(
							"staffs",
							yearIndex,
							each,
							"male"
						)}
						margin="normal"
						variant="outlined"
					/>
				)
			},
			{
				title: "Female Staff",
				dataIndex: "sn",
				key: "female",
				render: each => (
					<InputNumber
						name="female"
						type="number"
						min={0}
						value={
							staffs &&
							staffs[yearIndex] &&
							staffs[yearIndex][each] &&
							staffs[yearIndex][each].female
						}
						onChange={this.handleMultipleInput(
							"staffs",
							yearIndex,
							each,
							"female"
						)}
						margin="normal"
						variant="outlined"
					/>
				)
			},
			{
				title: "Total",
				dataIndex: "sn",
				key: "total",
				render: each => (
					<p>
						{staffs && staffs[yearIndex] && staffs[yearIndex][each]
							? parseInt(staffs[yearIndex][each].female || 0) +
							  parseInt(staffs[yearIndex][each].male || 0)
							: 0}
					</p>
				)
			},
			{
				title: "Action",
				key: "action",
				dataIndex: "sn",
				render: index => (
					<Button
						type="dashed"
						onClick={this.removePeoplRow("staffs", yearIndex, index)}
					>
						<Icon style={{ color: "red" }} type="minus" />
						{remove[selectedLanguage]}
					</Button>
				)
			}
		];

		const dataSource = [...Array(maxStaffsIndex[yearIndex]).keys()].map(sn => ({
			sn,
			level: ""
		}));
		let total =
			staffs && staffs[yearIndex] && staffs[yearIndex].length > 0
				? staffs[yearIndex].reduce(
						(acc, cur) => {
							acc.male += parseInt(cur.male || 0);
							acc.female += parseInt(cur.female || 0);
							return acc;
						},
						{ male: 0, female: 0 }
				  )
				: { male: 0, female: 0 };
		return (
			<Grid xs={12}>
				<Table
					dataSource={dataSource}
					columns={columns}
					footer={() => (
						<p>
							Total Male: {getNepaliNumber(total.male)} and Total female:{" "}
							{getNepaliNumber(total.female)}
						</p>
					)}
					pagination={false}
					title={() => (
						<Button type="dashed" onClick={this.addDhikka("staffs", yearIndex)}>
							<Icon type="plus" />
							{add[selectedLanguage]}
						</Button>
					)}
				/>
			</Grid>
		);
	};

	getTeacherUI = yearIndex => {
		const { maxTeachersIndex, teachers } = this.state;
		const columns = [
			{
				title: "S.N",
				dataIndex: "sn",
				key: "sn",
				render: index => <p>{getNepaliNumber(index + 1)}</p>
			},
			{
				title: "Title",
				dataIndex: "sn",
				key: "appointAs",
				render: each => (
					<Select
						placeholder="------------"
						name="appointAs"
						value={
							teachers &&
							teachers[yearIndex] &&
							teachers[yearIndex][each] &&
							teachers[yearIndex][each].appointAs
						}
						onChange={this.handleMultipleInput(
							"teachers",
							yearIndex,
							each,
							"appointAs"
						)}
						style={{ minWidth: 100 }}
					>
						{teacherTitleList.map(level => (
							<Option value={level.value}>{level[selectedLanguage]}</Option>
						))}
					</Select>
				)
			},
			{
				title: "Male Teacher",
				dataIndex: "sn",
				key: "male",
				render: each => (
					<InputNumber
						name="male"
						type="number"
						min={0}
						value={
							teachers &&
							teachers[yearIndex] &&
							teachers[yearIndex][each] &&
							teachers[yearIndex][each].male
						}
						onChange={this.handleMultipleInput(
							"teachers",
							yearIndex,
							each,
							"male"
						)}
						margin="normal"
						variant="outlined"
					/>
				)
			},
			{
				title: "Female Teacher",
				dataIndex: "sn",
				key: "female",
				render: each => (
					<InputNumber
						name="female"
						type="number"
						min={0}
						value={
							teachers &&
							teachers[yearIndex] &&
							teachers[yearIndex][each] &&
							teachers[yearIndex][each].female
						}
						onChange={this.handleMultipleInput(
							"teachers",
							yearIndex,
							each,
							"female"
						)}
						margin="normal"
						variant="outlined"
					/>
				)
			},
			{
				title: "Total",
				dataIndex: "sn",
				key: "total",
				render: each => (
					<p>
						{teachers && teachers[yearIndex] && teachers[yearIndex][each]
							? parseInt(teachers[yearIndex][each].female || 0) +
							  parseInt(teachers[yearIndex][each].male || 0)
							: 0}
					</p>
				)
			},
			{
				title: "Action",
				key: "action",
				dataIndex: "sn",
				render: index => (
					<Button
						type="dashed"
						onClick={this.removePeoplRow("teachers", yearIndex, index)}
					>
						<Icon style={{ color: "red" }} type="minus" />
						{remove[selectedLanguage]}
					</Button>
				)
			}
		];

		const dataSource = [...Array(maxTeachersIndex[yearIndex]).keys()].map(
			sn => ({
				sn,
				level: ""
			})
		);
		let total =
			teachers && teachers[yearIndex] && teachers[yearIndex].length > 0
				? teachers[yearIndex].reduce(
						(acc, cur) => {
							acc.male += parseInt(cur.male || 0);
							acc.female += parseInt(cur.female || 0);
							return acc;
						},
						{ male: 0, female: 0 }
				  )
				: { male: 0, female: 0 };
		return (
			<Grid xs={12}>
				<Table
					dataSource={dataSource}
					columns={columns}
					footer={() => (
						<p>
							Total Male: {getNepaliNumber(total.male)} and Total female:{" "}
							{getNepaliNumber(total.female)}
						</p>
					)}
					pagination={false}
					title={() => (
						<Button
							type="dashed"
							onClick={this.addDhikka("teachers", yearIndex)}
						>
							<Icon type="plus" />
							{add[selectedLanguage]}
						</Button>
					)}
				/>
			</Grid>
		);
	};

	getYearUI = () => {
		const { maxYearIndex } = this.state;
		return [...Array(maxYearIndex).keys()].map(yearIndex => (
			<Grid>
				<Divider orientation="left">{student[selectedLanguage]}</Divider>
				{this.getStudentUI(yearIndex)}
				<Divider orientation="left">{staff[selectedLanguage]}</Divider>
				{this.getStaffUI(yearIndex)}
				<Divider orientation="left">{teacher[selectedLanguage]}</Divider>
				{this.getTeacherUI(yearIndex)}
				{parseInt(yearIndex) === maxYearIndex - 1 ? (
					<Grid container spacing={2}>
						<Button
							type="dashed"
							style={{
								marginTop: "20px",
								marginLeft: "auto",
								marginRight: "auto"
							}}
							onClick={this.addDhikka("year")}
						>
							<Icon type="plus" />
							{add[selectedLanguage]}
						</Button>
					</Grid>
				) : (
					<Grid container spacing={2}>
						<Button
							style={{
								marginTop: "20px",
								marginLeft: "auto",
								marginRight: "auto"
							}}
							type="dashed"
							onClick={this.removeMainDhikka(yearIndex)}
						>
							<Icon style={{ color: "red" }} type="minus" />
							{remove[selectedLanguage]}
						</Button>
					</Grid>
				)}
			</Grid>
		));
	};

	addFacility = () => {
		this.setState(prevState => ({
			maxFacilityIndex: prevState.maxFacilityIndex + 1
		}));
	};

	handleFacilityChange = (index, value) => {
		// arr.splice(2, 1, "Lene");
		// event.persist();

		this.setState(prevState => {
			const facility = [...prevState.facility];

			const data = {};
			data.name = value;
			data.description = isEmpty(facility[index])
				? ""
				: facility[index].description;
			facility.splice(index, 1, data);
			return { facility };
		});
	};

	handleDescriptionChange = index => event => {
		const description = event.target.value;
		this.setState(prevState => {
			const facility = [...prevState.facility];

			const data = {};
			data.description = description;
			data.name = isEmpty(facility[index]) ? "" : facility[index].name;
			facility.splice(index, 1, data);
			return { facility };
		});
	};

	removeRow = index => () => {
		this.setState(prevState => {
			const facility = [...prevState.facility];
			facility.splice(index, 1);
			return { facility, maxFacilityIndex: prevState.maxFacilityIndex - 1 };
		});
	};

	getFacilityUI = () => {
		const { maxFacilityIndex, facility } = this.state;
		const columns = [
			{
				title: "S.N",
				dataIndex: "sn",
				key: "sn",
				render: index => <p>{getNepaliNumber(index + 1)}</p>
			},
			{
				title: "School Facility",
				dataIndex: "sn",
				key: "name",
				render: each => (
					<Select
						name="name"
						// style={{ width: "50%" }}
						placeholder="Please select"
						value={facility && facility[each] && facility[each].name}
						onChange={value => this.handleFacilityChange(each, value)}
					>
						{schoolFacility.map(facility => (
							<Option value={facility.value}>
								{facility[selectedLanguage]}
							</Option>
						))}
					</Select>
				)
			},
			{
				title: "Remarks",
				dataIndex: "sn",
				key: "description",
				render: each => (
					<TextArea
					className={
						this.props.switchCheck === true ? "preeti-font" : ""
					  }
						name="description"
						value={facility && facility[each] && facility[each].description}
						onChange={this.handleDescriptionChange(each)}
						rows={4}
					/>
				)
			},
			{
				title: "Action",
				key: "action",
				dataIndex: "sn",
				render: index =>
					parseInt(index) === maxFacilityIndex - 1 ? (
						<Button type="dashed" onClick={this.addFacility}>
							<Icon type="plus" />
							{add[selectedLanguage]}
						</Button>
					) : (
						<Button type="dashed" onClick={this.removeRow(index)}>
							<Icon style={{ color: "red" }} type="minus" />
							{remove[selectedLanguage]}
						</Button>
					)
			}
		];

		const dataSource = [...Array(maxFacilityIndex).keys()].map(sn => ({
			sn,
			level: ""
		}));
		return (
			<Grid xs={12}>
				<Table dataSource={dataSource} columns={columns} pagination={false} />
			</Grid>
		);
	};

	render() {
		const { classes, title } = this.props;
		const { previewVisible, previewImage } = this.state;
		const fileList = this.state.fileList;
		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);

		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form onSubmit={this.onSubmit}>
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						spacing={3}
					>
						<Grid item xs={6}>
							<Form.Item label={name[selectedLanguage]}>
								<Input
								className={
									this.props.switchCheck === true ? "preeti-font" : ""
								  }
									onChange={this.handleInputChange("name")}
									value={this.state.name}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={wardQuestion[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={val => this.handleSelectChange("ward", val)}
									value={this.state.ward}
									disabled={this.state.isView}
								>
									{ward.map(woda => (
										<Option value={woda.value}>{woda.ward}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={address[selectedLanguage]}>
								<Input
								className={
									this.props.switchCheck === true ? "preeti-font" : ""
								  }
									onChange={this.handleInputChange("address")}
									value={this.state.address}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={schooltype[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={val => this.handleSelectChange("schoolType", val)}
									value={this.state.schoolType}
									disabled={this.state.isView}
								>
									{instituteType.map(type => (
										<Option value={type.value}>{type[selectedLanguage]}</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={phone[selectedLanguage]}>
								<Input
									type="number"
									onChange={this.handleInputChange("phone")}
									value={this.state.phone}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={website[selectedLanguage]}>
								<Input
									onChange={this.handleInputChange("website")}
									value={this.state.website}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={email[selectedLanguage]}>
								<Input
									type="email"
									onChange={this.handleInputChange("email")}
									value={this.state.email}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={latitude[selectedLanguage]}>
								<Input
									type="number"
									onChange={this.handleInputChange("latitude")}
									value={this.state.latitude}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={longitude[selectedLanguage]}>
								<Input
									type="number"
									onChange={this.handleInputChange("longitude")}
									value={this.state.longitude}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={schoollevel[selectedLanguage]}>
								<Select
									placeholder="------------"
									onChange={val => this.handleSelectChange("schoolLevel", val)}
									value={this.state.schoolLevel}
									disabled={this.state.isView}
								>
									{schoolLevel.map(level => (
										<Option value={level.value}>
											{level[selectedLanguage]}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={photo[selectedLanguage]}>
								<Fragment>
									{!this.state.isView ? (
										<Upload
											name="file"
											// beforeUpload={this.props.beforeUpload}
											multiple={false}
											// action="/api/imageUpload/upload"
											accept="image/*"
											listType="picture-card"
											fileList={
												fileList.instituteImage && fileList.instituteImage.image
											}
											onPreview={this.handlePreview}
											onChange={fileList =>
												this.handleChangeFile(
													"instituteImage",
													fileList,
													"school"
												)
											}
											disabled={this.state.isView}
										>
											{fileList &&
											fileList.instituteImage &&
											fileList.instituteImage.image &&
											fileList.instituteImage.image.length === 1
												? null
												: uploadButton}
										</Upload>
									) : (
										<Modal
											visible={previewVisible}
											footer={null}
											onCancel={this.handleCancel}
										>
											<img
												alt="example"
												style={{ width: "100%" }}
												src={previewImage}
											/>
										</Modal>
									)}
								</Fragment>
							</Form.Item>
						</Grid>
						{/* <Divider orientation="left">{student[selectedLanguage]}</Divider> */}
						<Grid item xs={12}>
							{this.getYearUI()}
						</Grid>
						<Grid item xs={6} style={{ marginTop: "20px" }}>
							<Form.Item label={cberga[selectedLanguage]}>
								<RadioGroup
									name="isCberga"
									onChange={this.handleChange}
									value={this.state.isCberga}
								>
									<Radio value="yes" disabled={this.state.isView}>
										{yes[selectedLanguage]}
									</Radio>
									<Radio value="no" disabled={this.state.isView}>
										{no[selectedLanguage]}
									</Radio>
								</RadioGroup>
							</Form.Item>
						</Grid>
						<Divider orientation="left">
							{schoolFacilityTitle[selectedLanguage]}
						</Divider>

						<Grid item xs={12}>
							{this.getFacilityUI()}
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={classNineEducation[selectedLanguage]}>
								<RadioGroup
									name="classNineEducation"
									onChange={this.handleChange}
									value={this.state.classNineEducation}
								>
									<Radio value="yes" disabled={this.state.isView}>
										{yes[selectedLanguage]}
									</Radio>
									<Radio value="no" disabled={this.state.isView}>
										{no[selectedLanguage]}
									</Radio>
								</RadioGroup>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={malestudent[selectedLanguage]}>
								<Input
									type="number"
									onChange={this.handleInputChange("classNineMale")}
									value={this.state.classNineMale}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={femalestudent[selectedLanguage]}>
								<Input
									type="number"
									onChange={this.handleInputChange("classNineFemale")}
									value={this.state.classNineFemale}
								/>
							</Form.Item>
						</Grid>

						{classNineEducation && (
							<Grid item xs={6}>
								<Form.Item label={classNineEducationYes[selectedLanguage]}>
									<Input
									className={
										this.props.switchCheck === true ? "preeti-font" : ""
									  }
										onChange={this.handleInputChange("classNineSubject")}
										value={this.state.classNineSubject}
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>
						)}

						<Divider orientation="left">{gharTitle[selectedLanguage]}</Divider>
						<Grid item xs={6}>
							<Form.Item label={kachiCount[selectedLanguage]}>
								<Input
									type="number"
									onChange={this.handleInputChange("buildingKacchiCount")}
									value={this.state.buildingKacchiCount}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={pakkiCount[selectedLanguage]}>
								<Input
									type="number"
									onChange={this.handleInputChange("bulidingPakkiCount")}
									value={this.state.bulidingPakkiCount}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						{/* <Divider orientation="left">{roomTitle[selectedLanguage]}</Divider> */}
						<Grid item xs={6}>
							<Form.Item label={kachiRoomCount[selectedLanguage]}>
								<Input
									type="number"
									onChange={this.handleInputChange("roomKacchiCount")}
									value={this.state.roomKacchiCount}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={pakkiRoomCount[selectedLanguage]}>
								<Input
									type="number"
									onChange={this.handleInputChange("roomPakkiCount")}
									value={this.state.roomPakkiCount}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={benchCapacity[selectedLanguage]}>
								<Input
									type="number"
									onChange={this.handleInputChange("benchCapacity")}
									value={this.state.benchCapacity}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={establish[selectedLanguage]}>
								<CodeInput
									fields={4}
									type="number"
									values={this.state.establishedAt + ""}
									onComplete={this.handleReactInputCode("establishedAt")}
									autoFocus="false"
								/>
							</Form.Item>
						</Grid>

						<Grid item xs={6}>
							<Form.Item label={furniture[selectedLanguage]}>
								<RadioGroup
									name="enoughFurniture"
									onChange={this.handleChange}
									value={this.state.enoughFurniture}
								>
									<Radio value="yes" disabled={this.state.isView}>
										{yes[selectedLanguage]}
									</Radio>
									<Radio value="no" disabled={this.state.isView}>
										{no[selectedLanguage]}
									</Radio>
								</RadioGroup>
							</Form.Item>
						</Grid>

						<Divider orientation="left">{ground[selectedLanguage]}</Divider>
						<Grid item xs={6}>
							<Form.Item label={area[selectedLanguage]}>
								<Input
									type="number"
									addonAfter="square meter"
									onChange={this.handleInputChange("groundArea")}
									value={this.state.groundArea}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={childplayground[selectedLanguage]}>
								<RadioGroup
									name="childPlayGround"
									onChange={this.handleChange}
									value={this.state.childPlayGround}
								>
									<Radio value="yes" disabled={this.state.isView}>
										{yes[selectedLanguage]}
									</Radio>
									<Radio value="no" disabled={this.state.isView}>
										{no[selectedLanguage]}
									</Radio>
								</RadioGroup>
							</Form.Item>
						</Grid>
						<Divider orientation="left">{toilet[selectedLanguage]}</Divider>
						<Grid item xs={6}>
							<Form.Item label={hastoilet[selectedLanguage]}>
								<RadioGroup
									name="hasToilet"
									onChange={this.handleChange}
									value={this.state.hasToilet}
								>
									<Radio value="yes" disabled={this.state.isView}>
										{yes[selectedLanguage]}
									</Radio>
									<Radio value="no" disabled={this.state.isView}>
										{no[selectedLanguage]}
									</Radio>
								</RadioGroup>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={maleCount[selectedLanguage]}>
								<Input
									type="number"
									onChange={this.handleInputChange("maleToilet")}
									value={this.state.maleToilet}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={femaleCount[selectedLanguage]}>
								<Input
									type="number"
									onChange={this.handleInputChange("femaleToilet")}
									value={this.state.femaleToilet}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Grid item xs={6}>
							<Form.Item label={staffCount[selectedLanguage]}>
								<Input
									type="number"
									onChange={this.handleInputChange("staffToilet")}
									value={this.state.staffToilet}
									disabled={this.state.isView}
								/>
							</Form.Item>
						</Grid>
						<Divider />

						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								disabled={this.state.isView}
							>
								{this.props.isUpdate ? "update" : add[selectedLanguage]}
							</Button>
						</Form.Item>
					</Grid>
				</Form>
			</Paper>
		);
	}
}

const styles = theme => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2
	}
});

// const formItemLayout = {
// 	labelCol: {
// 		xs: { span: 24 },
// 		sm: { span: 8 }
// 	},
// 	wrapperCol: {
// 		xs: { span: 24 },
// 		sm: { span: 16 }
// 	}
// 	// wrapperCol: {
// 	// 	xs: { span: 24, offset: 0 },
// 	// 	sm: { span: 20, offset: 4 }
// 	// }
// };

const mapStateToProps = state => ({});

export default withStyles(styles)(
	connect(mapStateToProps, { addInstitutionSurvey })(withRouter(School))
);
