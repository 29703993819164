import { Grid, IconButton, Modal } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  Button,
  Radio,
  Form,
  Icon,
  Input,
  message,
  Select,
  Typography,
  Upload
} from "antd";
import Axios from "axios";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import {
  add,
  photo,
  remarks,
  name,
  designation,
  phone,
  department
} from "../../../variable/institute";
import "../../../fonts/fonts.css";
import { getRmStaff } from "./api";

const { TextArea } = Input;

const { Option } = Select;

class VillageCouncilStaff extends Component {
  random = (low, high) => {
    return Math.random() * (high - low) + low;
  };
  state = {
    // image upload state
    uniqueIdentifier:
      this.props.match.params.category +
      "_" +
      this.random(10000, 99999) +
      this.random(1000, 9999),
    previewVisible: false,
    previewImage: "",
    fileList: {
      industryImage: []
    },
    loaded: 0,
    // other
    remarks: "",
    photo: "",
    name: "",
    designation: "",
    phone: "",
    department: ""
  };

  // image functions uplocad
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    });
  };

  // imageUpload
  handleChangeFile = (name, value, imageName) => {
    //name ma house and value ma list of filelist
    console.log("CF", value);
    this.setState(
      prevState => ({
        ...prevState,
        fileList: {
          ...prevState.fileList,
          [name]: {
            image: value.fileList,
            fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg"
          }
        }
      }),
      () => {
        if (value.fileList.length > 0) this.handleUpload(name, imageName);
        if (value.fileList.length === 0) this.removeUpload(name, imageName);
      }
    );
  };

  // upload image
  handleUpload = (name, imageName) => {
    const fileList = this.state.fileList[name];
    const data = new FormData();
    const imageObject = fileList.image[0].originFileObj;

    let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
    data.append("file", imageObject, fileName);

    Axios.post("/api/imageUpload/upload", data, {
      onUploadProgress: ProgressEvent => {
        this.setState({
          loaded: Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
        });
      }
    })
      .then(res => {
        // then print response status
        message.success("upload successfully.");
        console.log(res.statusText);
      })
      .catch(err => message.error("upload failed."));
  };

  // remove uploaded image
  removeUpload = imageName => {
    let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

    Axios.get(`/api/imageUpload/delete/${fileName}`)
      .then(res => {
        // then print response status
        message.success("deleted successfully.");
        console.log(res.statusText);
      })
      .catch(err => message.error("upload failed."));
  };

  componentDidMount() {
    let rmStaffId = this.props.match.params.id;
    if (rmStaffId) {
      getRmStaff(rmStaffId).then(res =>
        this.setState({
          department: res.data.department,
          remarks: res.data.remarks,
          name: res.data.name,
          designation: res.data.designation,
          phone: res.data.contactNumber,
          // image
          fileList: {
            tourism: {
              fileName: res.data.uniqueIdentifier + "_institute.jpg",
              image: [
                {
                  uid: "-1",
                  name: `${res.data.uniqueIdentifier}_institute.jpg`,
                  status: "done",
                  url: `/api/imageUpload/image/${res.data.uniqueIdentifier}_institute.jpg`
                }
              ]
            }
          }
        })
      );
    }
  }

  onSubmit = event => {
    event.preventDefault();
    const newInstitute = {
      uniqueIdentifier: this.state.uniqueIdentifier,
      department: this.state.department,
      remarks: this.state.remarks,
      name: this.state.name,
      designation: this.state.designation,
      phone: this.state.phone
    };

    if (this.props.isUpdate) {
      newInstitute.id = this.props.match.params.id;
      this.props.addInstitutionSurvey(
        "rmStaff",
        newInstitute,
        this.props.history,
        this.props.match.params.category
      );
    } else
      this.props.addInstitutionSurvey(
        "rmStaff",
        newInstitute,
        this.props.history,
        this.props.match.params.category
      );
    // this.props.history.push(
    //   `/survey-by-institution/show/${this.props.match.params.category}`
    // );
  };
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  handleSelectChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  render() {
    const { classes, title } = this.props;
    const { previewVisible, previewImage } = this.state;
    const fileList = this.state.fileList;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <Paper className={classes.root} elevation={1}>
        <Typography variant="h5">{title}</Typography>
        <Form onSubmit={this.onSubmit}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item xs={6}>
              <Form.Item label={name[selectedLanguage]}>
                <Input
                  className={
                    this.props.switchCheck === true ? "preeti-font" : ""
                  }
                  onChange={this.handleChange("name")}
                  value={this.state.name}
                />
              </Form.Item>
            </Grid>

            <Grid item xs={6}>
              <Form.Item label={designation[selectedLanguage]}>
                <Input
                  className={
                    this.props.switchCheck === true ? "preeti-font" : ""
                  }
                  onChange={this.handleChange("designation")}
                  value={this.state.designation}
                />
              </Form.Item>
            </Grid>

            <Grid item xs={6}>
              <Form.Item label={department[selectedLanguage]}>
                <Input
                  className={
                    this.props.switchCheck === true ? "preeti-font" : ""
                  }
                  onChange={this.handleChange("department")}
                  value={this.state.department}
                />
              </Form.Item>
            </Grid>

            <Grid item xs={6}>
              <Form.Item label={phone[selectedLanguage]}>
                <Input
                  type="number"
                  min={0}
                  onChange={this.handleChange("phone")}
                  value={this.state.phone}
                />
              </Form.Item>
            </Grid>

            <Grid item xs={6}>
              <Form.Item label={remarks[selectedLanguage]}>
                <Input
                  className={
                    this.props.switchCheck === true ? "preeti-font" : ""
                  }
                  onChange={this.handleChange("remarks")}
                  value={this.state.remarks}
                />
              </Form.Item>
            </Grid>

            <Grid item xs={6}>
              <Form.Item label={photo[selectedLanguage]}>
                <Fragment>
                  {!this.state.isView ? (
                    <Upload
                      name="file"
                      // beforeUpload={this.props.beforeUpload}
                      multiple={false}
                      // action="/api/imageUpload/upload"
                      accept="image/*"
                      listType="picture-card"
                      fileList={
                        fileList.instituteImage && fileList.instituteImage.image
                      }
                      onPreview={this.handlePreview}
                      onChange={fileList =>
                        this.handleChangeFile(
                          "instituteImage",
                          fileList,
                          "institute"
                        )
                      }
                      disabled={this.state.isView}
                    >
                      {fileList &&
                      fileList.instituteImage &&
                      fileList.instituteImage.image &&
                      fileList.instituteImage.image.length === 1
                        ? null
                        : uploadButton}
                    </Upload>
                  ) : (
                    <Modal
                      visible={previewVisible}
                      footer={null}
                      onCancel={this.handleCancel}
                    >
                      <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={previewImage}
                      />
                    </Modal>
                  )}
                </Fragment>
              </Form.Item>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={this.state.isView}
              >
                {add[selectedLanguage]}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Paper>
    );
  }
}

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  }
});

const mapStateToProps = state => ({});

export default withStyles(styles)(
  connect(mapStateToProps, { addInstitutionSurvey })(
    withRouter(VillageCouncilStaff)
  )
);
