import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import AspectRatio from "react-aspect-ratio";
import ImageZoom from "react-medium-image-zoom";
import getNepalNumber from "../../../../../utils/getNepaliNumber";
import { no, selectedLanguage, yes } from "../../../../../variable/global";
import {
  genderFemale,
  genderMale,
  genderThird,
  genderTotal,
  gharNumber,
  gharTitle,
  wardQuestion,
} from "../../../../../variable/houseSurvey";
import {
  QuestionAnswer,
  Title,
} from "../../../../common/frequent/QuestionAnswer";

const NoImageFound = require("../../../../../image/NoImageFound.jpg");

function mapValue(value, obj) {
  return obj[value];
}

function checkImage(path, fallback) {
  return new Promise((resolve) => {
    var img = new Image();
    img.src = path;
    img.onload = () => {
      console.log("loaded");
      resolve(path);
    };
    img.onerror = () => {
      console.log("errored");
      resolve(NoImageFound);
    };
  });
}

class HouseIntro extends Component {
  state = {
    link: "old",
  };
  getYesNo = (data) => (data ? yes[selectedLanguage] : no[selectedLanguage]);

  // static getDerivedStateFromProps(props, state) {
  // 	checkImage(
  // 		`/images/${props.houseDetails.houseId}/house.jpg`,
  // 		"../../../../../image/NoImageFound.jpg"
  // 	).then(res => {
  // 		console.log("componentDidUpdate", res);
  // 		return {
  // 			link: res
  // 		};
  // 	});
  // }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.houseDetails !== prevProps.houseDetails) {
      this.setState({ link: "updated" });
    }
  }

  render() {
    const { classes, houseNumber, houseDetails, image } = this.props;

    const { link } = this.state;
    // image.src = `/images/${houseDetails.houseId}/house.jpg`;
    return (
      <Grid item container direction="column" className={classes.container}>
        <Title title={gharTitle} />

        <Grid item container direction="row">
          <Grid item container xs={8}>
            {/* first */}
            <Grid item container direction="row" className={classes.eachQnAns}>
              <Grid item xs={6}>
                <QuestionAnswer
                  question={wardQuestion[selectedLanguage]}
                  answer={
                    houseDetails &&
                    houseDetails.wardNumber &&
                    getNepalNumber(houseDetails.wardNumber)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <QuestionAnswer
                  question={gharNumber[selectedLanguage]}
                  answer={
                    houseDetails &&
                    houseDetails.houseNumber &&
                    getNepalNumber(houseDetails.houseNumber)
                  }
                />
              </Grid>
            </Grid>
            {/* end */}
            <Grid item container direction="row" className={classes.eachQnAns}>
              <Grid item xs={6}>
                <QuestionAnswer
                  question="साविक गा.वि.स"
                  answer={
                    houseDetails && houseDetails.oldVDC && houseDetails.oldVDC
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <QuestionAnswer
                  question="साविक वडा नं"
                  answer={
                    houseDetails &&
                    houseDetails.oldWardNumber &&
                    getNepalNumber(houseDetails.oldWardNumber)
                  }
                />
              </Grid>
            </Grid>

            <Grid item container direction="row" className={classes.eachQnAns}>
              <Grid item xs={6}>
                <QuestionAnswer
                  question="टोल वा वस्तीको नाम"
                  answer={
                    houseDetails &&
                    houseDetails.toleName &&
                    houseDetails.toleName
                  }
                />
              </Grid>
              {houseDetails.toleNumber && (
                <Grid item xs={6}>
                  <QuestionAnswer
                    question="टोल संकेत नं"
                    answer={
                      houseDetails &&
                      houseDetails.toleNumber &&
                      houseDetails.toleNumber
                    }
                  />
                </Grid>
              )}
            </Grid>

            <Grid item container direction="row" className={classes.eachQnAns}>
              <Grid item>
                <QuestionAnswer
                  question="मार्गको नाम (घर छेउको बाटो )"
                  answer={
                    houseDetails &&
                    houseDetails.streetName &&
                    houseDetails.streetName
                  }
                />
              </Grid>
            </Grid>

            <Grid item container direction="row" className={classes.eachQnAns}>
              <Grid item xs={6}>
                <QuestionAnswer
                  question="परिवारको यहाको बसोबास स्थिती"
                  answer={
                    houseDetails &&
                    mapValue(houseDetails.livingType, {
                      permanent: "अस्थाई",
                      temporary: "स्थाई",
                      squatter: "सुकुम्बासी",
                    })
                  }
                />
              </Grid>
              {houseDetails.yearSettled && (
                <Grid item xs={6}>
                  <QuestionAnswer
                    question="बसोबास गरेको कति वर्ष भयो"
                    answer={
                      houseDetails &&
                      houseDetails.yearSettled &&
                      `${getNepalNumber(houseDetails.yearSettled)} बर्ष`
                    }
                  />
                </Grid>
              )}
            </Grid>

            <Grid
              item
              container
              direction="column"
              className={classes.eachQnAns}
            >
              <QuestionAnswer question="परिवार सदस्य जानकारी" />
              <Grid item xs={6}>
                <Table
                  style={{ width: 200, marginTop: 20 }}
                  className={classes.resultTable}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        className={classes.tableHeader}
                        style={{ paddingLeft: 0 }}
                      >
                        {genderFemale[selectedLanguage]}
                      </TableCell>
                      <TableCell align="left">
                        {genderMale[selectedLanguage]}
                      </TableCell>
                      {houseDetails.thirdNumber !== 0 && (
                        <TableCell align="left">
                          {genderThird[selectedLanguage]}
                        </TableCell>
                      )}
                      <TableCell align="left" style={{ paddingRight: 0 }}>
                        {genderTotal[selectedLanguage]}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left" className={classes.firstChildTD}>
                        {houseDetails &&
                          houseDetails.femaleNumber &&
                          getNepalNumber(houseDetails.femaleNumber)}
                      </TableCell>
                      <TableCell align="left">
                        {houseDetails &&
                          houseDetails.maleNumber &&
                          getNepalNumber(houseDetails.maleNumber)}
                      </TableCell>
                      {houseDetails.thirdNumber !== 0 && (
                        <TableCell align="left">
                          {houseDetails &&
                            houseDetails.thirdNumber &&
                            getNepalNumber(houseDetails.thirdNumber)}
                        </TableCell>
                      )}
                      <TableCell align="left" className={classes.lastChildTD}>
                        {houseDetails &&
                          houseDetails.totalFamilyNumber &&
                          getNepalNumber(houseDetails.totalFamilyNumber)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container xs={4}>
            {true && (
              <AspectRatio
                ratio="3/4"
                style={{ width: "100%", paddingRight: 40 }}
              >
                <ImageZoom
                  image={{
                    src: `/api/imageUpload/image/${this.props.houseNumber}_house.jpg`,
                    alt: "Stnepal ",
                    className: "img",
                    style: { width: "100%" },
                  }}
                  zoomImage={{
                    src: `/api/imageUpload/image/${this.props.houseNumber}_house.jpg`,
                    alt: "stnepal",
                  }}
                />
              </AspectRatio>
            )}
            <div
              style={{ marginTop: 10, height: "50%", display: "inline-block" }}
            >
              <div style={{ display: "inline-block" }}>
                {true && (
                  <AspectRatio
                    ratio="3/4"
                    style={{ width: "50%", paddingRight: 40 }}
                  >
                    <ImageZoom
                      image={{
                        src: `/api/imageUpload/image/${this.props.houseNumber}_citizen_front.jpg`,
                        alt: "Stnepal ",
                        className: "img",
                        style: { width: "100%" },
                      }}
                      zoomImage={{
                        src: `/api/imageUpload/image/${this.props.houseNumber}_citizen_front.jpg`,
                        alt: "stnepal",
                      }}
                    />
                  </AspectRatio>
                )}
              </div>
              <div style={{ marginTop: 5, display: "inline-block" }}>
                {true && (
                  <AspectRatio
                    ratio="3/4"
                    style={{ width: "50%", paddingRight: 40 }}
                  >
                    <ImageZoom
                      image={{
                        src: `/api/imageUpload/image/${this.props.houseNumber}_citizen_back.jpg`,
                        alt: "Stnepal ",
                        className: "img",
                        style: { width: "100%" },
                      }}
                      zoomImage={{
                        src: `/api/imageUpload/image/${this.props.houseNumber}_citizen_back.jpg`,
                        alt: "stnepal",
                      }}
                    />
                  </AspectRatio>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  container: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 2,
  },
  tableHeader: {
    fontSize: "1rem",
  },
  firstChildTD: {
    paddingLeft: 0,
  },
  lastChildTD: {
    paddingRight: 0,
  },
});

HouseIntro.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HouseIntro);
